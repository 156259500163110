import React, { useState, useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import AuthContext from '../AuthContext';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FaCalendarAlt } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';

const PartnerModal = ({ visible, onClose, yourData }) => {
    const { i18n, t } = useTranslation();
    const [forms, setForms] = useState([{ firstname: '', lastname: '', birthdate: null }]);
    const [selectedAmount, setSelectedAmount] = useState(1);
    const [partnerModalState, setPartnerModalState] = useState('enterinfo');
    const [partnerData, setPartnerData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const currentLanguage = i18n.language;

    const { token } = useContext(AuthContext);

    if (!yourData) {
        return null;
    }

    const handleClose = () => {
        setForms([{ firstname: '', lastname: '', birthdate: null }]);
        setPartnerModalState('enterinfo');
        setPartnerData(null);
        onClose();
    };

    const validateForms = () => {
        for (let form of forms) {
            if (!form.firstname || !form.lastname || !form.birthdate) {
                return false;
            }
        }
        return true;
    };

    const handlePartnerSubmit = async () => {
        if (!validateForms()) {
            alert('Please fill out all fields for all partners.');
            return;
        }

        setLoading(true);
        try {
            const data = forms.map((form) => ({
                yourSevenNumber: yourData,
                partnerFirstName: form.firstname,
                partnerLastName: form.lastname,
                partnerBirthDate: form.birthdate.getDate(),
                partnerBirthMonth: form.birthdate.getMonth() + 1,
                partnerBirthYear: form.birthdate.getFullYear(),
            }));

            const response = await fetch(process.env.REACT_APP_API_URL + '/premium/partner', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const responseData = await response.json();
            setPartnerData(responseData);
            setPartnerModalState('fetched');
            setError('');
        } catch (error) {
            console.error('Error fetching data:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const updateForms = (index, field, value) => {
        const updatedForms = [...forms];
        updatedForms[index][field] = value;
        setForms(updatedForms);
    };

    const handleAmountChange = (value) => {
        const amount = parseInt(value, 10);
        const updatedForms = Array.from({ length: amount }, (_, index) => {
            return forms[index] || { firstname: '', lastname: '', birthdate: null };
        });
        setForms(updatedForms);
        setSelectedAmount(amount);
    };

    return (
        <Modal show={visible} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t('partner_description_title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {partnerModalState === 'enterinfo' ? (
                    <>
                        {error !== '' ? (
                            <h3 className="error-message">Error : {error}</h3>
                        ):
                            (<></>)
                        }
                        
                        <div className="mb-3">
                            <label className="form-label">Select Number of Partners</label>
                            <select
                                className="form-select"
                                value={selectedAmount}
                                onChange={(e) => handleAmountChange(e.target.value)}
                            >
                                {[1, 2, 3, 4].map((amount) => (
                                    <option key={amount} value={amount}>
                                        {amount} Partner{amount > 1 ? 's' : ''}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {forms.map((form, index) => (
                            <div key={index} className="border p-3 mb-3">
                                <h5>Partner {index + 1}</h5>
                                <div className="mb-3">
                                    <label className="form-label">{t('english_first_name')}</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={form.firstname}
                                        onChange={(e) => updateForms(index, 'firstname', e.target.value)}
                                        placeholder="Enter firstname"
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">{t('english_last_name')}</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={form.lastname}
                                        onChange={(e) => updateForms(index, 'lastname', e.target.value)}
                                        placeholder="Enter lastname"
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">{t('date_month_year_birth')}</label>
                                    <div className="input-group">
                                        <span className="input-group-text">
                                            <FaCalendarAlt />
                                        </span>
                                        <DatePicker
                                            selected={form.birthdate}
                                            onChange={(date) => updateForms(index, 'birthdate', date)}
                                            className="form-control"
                                            placeholderText="Select your birthdate"
                                            dateFormat="yyyy-MM-dd"
                                            showYearDropdown
                                            showMonthDropdown
                                            dropdownMode="select"
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}

                        <button
                            className="btn btn-primary w-100"
                            onClick={handlePartnerSubmit}
                            disabled={loading}
                        >
                            {loading ? 'Calculating...' : 'Calculate'}
                        </button>
                    </>
                ) : (
                    <>
                        {partnerData ? (
                            <div>
                                {partnerData.map((data, index) => (
                                    <div key={data.partnerSevenNumber || index}>
                                        <h3>{t('fullname')} : {data.partnerFirstName} {data.partnerLastName}</h3>
                                        <h3>{t('your_life_path_number')} : {data.yourSevenNumber}</h3>
                                        <h3>{t('your_partner_life_path_number')} : {data.partnerSevenNumber}</h3>
                                        <h3>{t('you_and_partner_sum')} : {data.partnerDescription.number}</h3>
                                        <p>{data.partnerDescription.meaningThai}</p>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p>Loading...</p>
                        )}
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PartnerModal;
