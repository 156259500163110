import React from 'react';

const Triangle = ({ data }) => {
  if (!data) return null; // Check if data is available

  return (
    <div className="triangle-layout">
      <img src="/triangle.png" className="triangle-background"/>
      <div className="triangle-data-container">
        <div className="triangle-first-line">
          <div className="triangle-first-left">
            <h1 className="triangle-number">{data.numberOne}</h1>
            <h1 className="triangle-number">{data.numberTwo}</h1>
          </div>

          <div className="triangle-first-right">
            <h1 className="triangle-number">{data.numberThree}</h1>
            <h1 className="triangle-number">{data.numberFour}</h1>
          </div>
        </div>

        <div className="triangle-second-line">
          <h1 className="triangle-second-left">{data.numberFive}</h1>
          <h1 className="triangle-second-right">{data.numberSix}</h1>
        </div>

        <h5 className="triangle-seven-number">{data.numberSeven}</h5>

        <div className="triangle-third-line">
          <div className="triangle-third-left">
            <h1 className="triangle-number">{data.numbersOnTheLeft[1]}</h1>
            <h1 className="triangle-number">{data.numbersOnTheLeft[3]}</h1>
          </div>

          <div className="triangle-third-right">
            <h1 className="triangle-number">{data.numbersOnTheRight[1]}</h1>
            <h1 className="triangle-number">{data.numbersOnTheRight[3]}</h1>
          </div>
        </div>

        <div className="triangle-forth-line">
          <h1 className="triangle-number-forth-left">{data.numbersOnTheLeft[5]}</h1>
          <h1 className="triangle-number">{data.numberEight}</h1>
          <h1 className="triangle-number">{data.numberNine}</h1>
          <h1 className="triangle-number-forth-right">{data.numbersOnTheRight[5]}</h1>
        </div>

        <h1 className="triangle-fift-line">
           {data.numbersOnTheBottom[5]} 
        </h1>

      </div>
      
    </div>
  );
};

export default Triangle;