import React, { useState, useContext, useEffect } from 'react';
import HeaderLayout from '../layouts/Header';
import FooterLayout from '../layouts/Footer';
import FooterWithCopyright from '../layouts/FooterWithCopyright';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "./Main.css";
import LoginModal from '../modals/LoginModal';
import { FaCalendarAlt } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import AuthContext from '../AuthContext';
import { t } from 'i18next';

const ProfileEditPage = () => {

    const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
    const showLoginModal = () => setIsLoginModalVisible(true);
    const cancelLoginModal = () => setIsLoginModalVisible(false);

    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [birthdate, setBirthdate] = useState('');
    const [telephoneNumber, setTelephoneNumber] = useState('');
    const [identificationNumber, setIdentificationNumber] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const { token, isAuthenticated, loading, mainProfile, fetchData } = useContext(AuthContext);

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        if (!token) {
            alert("No JWT token found.");
            return;
        }

        if (!birthdate) {
            alert("Please select a birthdate.");
            return;
        }
    
        // Extract birth date components
        const birthDay = birthdate.getDate();
        const birthMonth = birthdate.getMonth() + 1; // Months are zero-based
        const birthYear = birthdate.getFullYear();
    
        const data = {
            firstName: firstname,
            lastName: lastname,
            birthDate: birthDay,
            birthMonth: birthMonth,
            birthYear: birthYear,
            telephoneNumber: telephoneNumber,
            identificationNumber: identificationNumber
        };
    
        try {
            const response = await fetch(process.env.REACT_APP_API_URL+'/user/editmainprofile', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(data),
            });
    
            if (response.ok) {
                const responseData = await response.json();
                //console.log(responseData);
                await fetchData();
                setError('');
                navigate(`/`);
            } else {
                console.error('Failed to create profile:', response.statusText);
                setError(response.statusText);
            }
        } catch (error) {
            console.error('Error creating profile:', error);
            setError(error.message);
        }
    };

    const countryOptions = [
        'Thailand',
        'United States',
        'United Kingdom',
        'Japan',
        'Germany',
        'France',
        'Australia',
        'Canada',
        'India',
        'China',
    ];

    useEffect(() => {
        if(!isAuthenticated){
            navigate('/');
        }

        if (!loading && mainProfile) {
            setFirstname(mainProfile.firstName);
            setLastname(mainProfile.lastName);
            setTelephoneNumber(mainProfile.telephoneNumber);
            setIdentificationNumber(mainProfile.identificationNumber);
      
            // Create a valid Date object from birthDate, birthMonth, and birthYear
            if (mainProfile.birthDate && mainProfile.birthMonth && mainProfile.birthYear) {
              const defaultDate = new Date(
                mainProfile.birthYear, 
                mainProfile.birthMonth - 1, // Month is 0-indexed
                mainProfile.birthDate
              );
              setBirthdate(defaultDate);
            }
          }
    }, [loading]);

    return (
        <div className="background-element">
            <HeaderLayout onLoginClick={showLoginModal}/>

            <div className="page-content-container">

                {error !== '' ? (
                    <h3 className="error-message">Error : {error}</h3>
                    ):
                    <></>
                }

                <h1 className="page-text">แก้ไขข้อมูลส่วนตัว</h1>

                <div className="form-container mt-3">
                    <form onSubmit={handleFormSubmit} className="border p-4 rounded shadow-sm bg-light">
                        <div className="mb-3">
                            <label className="form-label">{t('english_first_name')}</label>
                            <input
                                type="text"
                                className="form-control"
                                value={firstname}
                                onChange={(e) => setFirstname(e.target.value)}
                                placeholder="Enter your firstname"
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">{t('english_last_name')}</label>
                            <input
                                type="text"
                                className="form-control"
                                value={lastname}
                                onChange={(e) => setLastname(e.target.value)}
                                placeholder="Enter your lastname"
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">{t('date_month_year_birth')}</label>
                            <div className="input-group">
                                <span className="input-group-text">
                                <FaCalendarAlt />
                                </span>
                                <DatePicker
                                selected={birthdate}
                                onChange={(date) => setBirthdate(date)}
                                className="form-control"
                                placeholderText="Select your birthdate"
                                dateFormat="yyyy-MM-dd"
                                showYearDropdown
                                showMonthDropdown
                                dropdownMode="select"
                                />
                            </div>
                        </div>

                        <div className="mb-3">
                            <label className="form-label">{t('identification_number')}</label>
                            <input
                                type="text"
                                className="form-control"
                                value={identificationNumber}
                                onChange={(e) => setIdentificationNumber(e.target.value)}
                                placeholder="Enter your ID"
                                required
                            />
                        </div>

                        <div className="mb-3">
                            <label className="form-label">{t('telephone_number')}</label>
                            <input
                                type="text"
                                className="form-control"
                                value={telephoneNumber}
                                onChange={(e) => setTelephoneNumber(e.target.value)}
                                placeholder="Enter your telephone number"
                                required
                            />
                        </div>
                        <button type="submit" className="btn btn-primary w-100">
                        Edit Profile
                        </button>
                    </form>
                </div>
            </div>

            <FooterLayout onLoginClick={showLoginModal}/>
            <FooterWithCopyright/>

            <LoginModal visible={isLoginModalVisible} onClose={cancelLoginModal} />
        </div>
    );
};

export default ProfileEditPage;
