import React, { useState, useEffect, useContext } from 'react';
import HeaderLayout from '../layouts/Header';
import FooterLayout from '../layouts/Footer';
import FooterWithCopyright from '../layouts/FooterWithCopyright';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Main.css';
import AuthContext from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import LoginModal from '../modals/LoginModal';
import { t } from 'i18next';
import { FaCalendarAlt } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';

const PremiumOtherPage = () => {
    const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
    const showLoginModal = () => setIsLoginModalVisible(true);
    const cancelLoginModal = () => setIsLoginModalVisible(false);

    const [error, setError] = useState('');
    
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [identificationNumber, setIdentificationNumber] = useState('');
    const [birthdate, setBirthdate] = useState('');
    const navigate = useNavigate();

    const { isAuthenticated, mainProfile, mainPremiumProfile, premiumStatus, loading, token } = useContext(AuthContext);

    const handleFormSubmit = async (event) => {
      event.preventDefault();
  
      if (!birthdate) {
        alert("Please select a birthdate.");
        return;
      }

      const birthDay = birthdate.getDate();
      const birthMonth = birthdate.getMonth() + 1;
      const birthYear = birthdate.getFullYear();
      var data;
      if(identificationNumber.length == 13){
        data = {
          firstName: firstname,
          lastName: lastname,
          identificationNumber: identificationNumber,
          birthDate: birthDay,
          birthMonth: birthMonth,
          birthYear: birthYear
        };
      }
      else if(identificationNumber.length == 0){
        data = {
          firstName: firstname,
          lastName: lastname,
          identificationNumber: identificationNumber,
          birthDate: birthDay,
          birthMonth: birthMonth,
          birthYear: birthYear
        };
      }
      else{
        alert("Please Enter correct identification number.");
        return;
      }
  
      try {
        const response = await fetch(process.env.REACT_APP_API_URL+'/profile/premium/other', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify(data),
        });
  
        if (!response.ok) {
            throw new Error('Failed to fetch data');
        } 

        const responseData = await response.json();
        setError('');
        navigate(`/premium/other/`+responseData.id);

      } catch (error) {
        console.error('Error creating profile:', error);
        setError(error.message);
      }
    };

    // Handle page initialization
    useEffect(() => {
        if(!loading && !isAuthenticated){
            navigate('/');
        }

        if(!loading && (!premiumStatus || premiumStatus.status !== "PREMIUM")){
            navigate('/premium/subscribe');
        }
        
        if(!loading && isAuthenticated && !premiumStatus.active){
            navigate('/premium/subscribe');
        }

    }, [isAuthenticated, mainProfile, navigate]);

    return (
        <div className="background-element">
          <HeaderLayout onLoginClick={showLoginModal}/>
  
          <div className="page-content-container">
            {error !== '' ? (
              <h3 className="error-message">Error : {error}</h3>
              ):
              <></>
            }

            <h1 className="page-text">โปรดเพิ่มข้อมูลส่วนตัวก่อนเริ่มใช้งาน</h1>
  
            <div className="form-container mt-3">
              <form onSubmit={handleFormSubmit} className="border p-4 rounded shadow-sm bg-light">
                <div className="mb-3">
                  <label className="form-label">{t('english_first_name')}</label>
                  <input
                      type="text"
                      className="form-control"
                      value={firstname}
                      onChange={(e) => setFirstname(e.target.value)}
                      placeholder="Enter your firstname"
                      required
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">{t('english_last_name')}</label>
                  <input
                      type="text"
                      className="form-control"
                      value={lastname}
                      onChange={(e) => setLastname(e.target.value)}
                      placeholder="Enter your lastname"
                      required
                  />
                </div>

                <div className="mb-3">
                  <label className="form-label">{t('identification_number')}</label>
                  <input
                      type="text"
                      className="form-control"
                      value={identificationNumber}
                      onChange={(e) => setIdentificationNumber(e.target.value)}
                      placeholder="Enter your ID"
                  />
                </div>
  
                <div className="mb-3">
                  <label className="form-label">{t('date_month_year_birth')}</label>
  
                  <div className="input-group">
                      <span className="input-group-text">
                      <FaCalendarAlt />
                      </span>
                      <DatePicker
                      selected={birthdate}
                      onChange={(date) => setBirthdate(date)}
                      className="form-control"
                      placeholderText="Select your birthdate"
                      dateFormat="yyyy-MM-dd"
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                      />
                  </div>
                </div>
  
                <button type="submit" className="btn btn-primary w-100">Calculate</button>
              </form>
            </div>
          </div>
  
          <FooterLayout onLoginClick={showLoginModal}/>
          <FooterWithCopyright/>
  
          <LoginModal visible={isLoginModalVisible} onClose={cancelLoginModal} />
        </div>
    );
};

export default PremiumOtherPage;
