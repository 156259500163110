import React from 'react';
import { useTranslation } from 'react-i18next';
import "./DirectionSection.css"

const DirectionSection = ({ directionData }) => {

  if (!directionData) return null; // Check if data is available

  const isGoodNumber = (value) => {
    if(value == 6 || value == 7 || value == 9){
        return true;
    }
    else{
        return false;
    }
  }

  return (
    <div className="direction-container" style={{backgroundImage: "./direction.png"}}>
        <img src="/direction.png" className="direction-background"/>
        <h4 className={isGoodNumber(directionData[0]) == true ? "direction-one direction-good" : "direction-one"}>{directionData[0]}</h4>
        <h4 className={isGoodNumber(directionData[1]) == true ? "direction-two direction-good" : "direction-two"}>{directionData[1]}</h4>
        <h4 className={isGoodNumber(directionData[2]) == true ? "direction-three direction-good" : "direction-three"}>{directionData[2]}</h4>
        <h4 className={isGoodNumber(directionData[3]) == true ? "direction-four direction-good" : "direction-four"}>{directionData[3]}</h4>
        <h4 className={isGoodNumber(directionData[4]) == true ? "direction-five direction-good" : "direction-five"}>{directionData[4]}</h4>
        <h4 className={isGoodNumber(directionData[5]) == true ? "direction-six direction-good" : "direction-six"}>{directionData[5]}</h4>
        <h4 className={isGoodNumber(directionData[6]) == true ? "direction-seven direction-good" : "direction-seven"}>{directionData[6]}</h4>
        <h4 className={isGoodNumber(directionData[7]) == true ? "direction-eight direction-good" : "direction-eight"}>{directionData[7]}</h4>
    </div>
  );
};

export default DirectionSection;