import React, { useState } from 'react';
import HeaderLayout from '../layouts/Header';
import FooterLayout from '../layouts/Footer';
import FooterWithCopyright from '../layouts/FooterWithCopyright';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "./Main.css";

import LoginModal from '../modals/LoginModal';

const ProductPage = () => {

    const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
    const showLoginModal = () => setIsLoginModalVisible(true);
    const cancelLoginModal = () => setIsLoginModalVisible(false);

    return (
        <div className="background-element">
            <HeaderLayout onLoginClick={showLoginModal}/>

            <div className="page-content-container">
                <h1 className="page-title">หน้าสินค้าและบริการ</h1>
                <img src="/book_cover.jpg" className="book-image"></img>

                <h1 className="page-title">และบริการอีกมากมายที่</h1>
                <a href="https://www.facebook.com/LightofSoul567" style={{display:"flex", justifyContent: "center", alignItems:"center"}}>
                    <img src="/facebook.png" className="facebook-image"/>
                </a>

                <a href="https://www.youtube.com/@lightofsoulbynut" style={{display:"flex", justifyContent: "center", alignItems:"center"}}>
                    <img src="/youtube.png"style={{width: "60%", borderRadius: "10px", marginTop: "20px"}}/>
                </a>

                
                <img src="/line-official.png"style={{width: "60%", maxWidth:"200px", borderRadius: "10px", marginTop: "20px"}}/>

            </div>

            <FooterLayout onLoginClick={showLoginModal}/>
            <FooterWithCopyright/>

            <LoginModal visible={isLoginModalVisible} onClose={cancelLoginModal} />
        </div>
    
    );
};

export default ProductPage;