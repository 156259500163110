import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const PotentialAndObstacleDescriptionFromTwoNumber = ({ visible, onClose, data }) => {
  const { i18n, t } = useTranslation();
  const currentLanguage = i18n.language;

  if (!data) return null; // Check if data is available

  return (
    <Modal show={visible} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t('potential_and_obstacle_two_number_description')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h1>{t('number')} : {data.number}</h1>
        <h3>{t('hidden_potential_title')}</h3>
        <p>{currentLanguage == 'en' ? data.potentialDescriptionEnglish : data.potentialDescriptionThai}</p>
        <h3>{t('obstacle_title')}</h3>
        <p>{currentLanguage == 'en' ? data.obstacleDescriptionEnglish : data.obstacleDescriptionThai}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PotentialAndObstacleDescriptionFromTwoNumber;