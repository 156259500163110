import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function LoginSuccess() {
    const navigate = useNavigate();

    useEffect(() => {
        try {
            // Log the current URL for debugging
            // console.log("Current URL:", window.location.href);

            // Extract the JWT from the query parameters
            const urlParams = new URLSearchParams(window.location.search);
            const token = urlParams.get('token');

            //console.log("Extracted token:", token);

            if (token) {
                // Store the JWT in localStorage
                localStorage.setItem('los-jwt', token);

                // Redirect to the homepage or dashboard
                navigate('/');
            } else {
                console.error('JWT not found in query parameters');
            }
        } catch (error) {
            console.error('Error processing login:', error);
        }
    }, [navigate]);

    return <div>Processing login...</div>;
}

export default LoginSuccess;
