import React, { useEffect, useState, useContext } from 'react';
import HeaderLayout from '../layouts/Header';
import FooterLayout from '../layouts/Footer';
import FooterWithCopyright from '../layouts/FooterWithCopyright';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "./Main.css";
import AuthContext from '../AuthContext';
import LoginModal from '../modals/LoginModal';
import { useNavigate } from 'react-router-dom';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import "./Premium.css";
import LifeBaseDescription from '../components/LifeBaseDescription';
import SubconsciousDescription from '../components/SubconsciousDescription';
import LifePathAndSubconsciousDescription from '../components/LifePathAndSubconscriousDescription';
import PotentialAndObstacleDescriptionFromSeven from '../components/PotentialAndObstacleDescriptionFromSeven';
import PotentialAndObstacleDescriptionFromTwoNumber from '../components/PotentialAndObstacleDescriptionFromTwoNumber';
import HiddenAndMissingDescription from '../components/HiddenAndMissingDescription';
import DirectionSection from '../components/DirectionSection';
import PartnerModal from '../components/PartnerModal';
import { useTranslation } from 'react-i18next';

const PremiumPage = () => {

  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const showLoginModal = () => setIsLoginModalVisible(true);
  const navigate = useNavigate();
  const cancelLoginModal = () => setIsLoginModalVisible(false);
  const { isAuthenticated, mainProfile, mainPremiumProfile, premiumStatus, loading, token } = useContext(AuthContext);

  const { i18n, t } = useTranslation();

  const currentLanguage = i18n.language;

  const [baseSelected, setBaseSelected] = useState('birthbase');

  const [error, setError] = useState('');

  const [loadingPremiumData, setLoadingPremiumData] = useState(true);

  const [triangleBirthBaseAD, setTriangleBirthDateBirthBaseAD] = useState(null);

  const [lifeBaseDescriptionBirthDateAD, setLifeBaseDescriptionBirthDateAD] = useState(null);
  const [lifeBaseDescriptionIdElevenTwelveAD, setLifeBaseDescriptionIdElevenTwelveAD] = useState(null);
  const [lifeBaseDescriptionIdTwelveThirteenAD, setLifeBaseDescriptionIdTwelveThirteenAD] = useState(null);
  const [lifeBaseDescriptionIdSumAD, setLifeBaseDescriptionIdSumAD] = useState(null);
  
  const [lifeBaseDescriptionBirthDateADYearBase, setLifeBaseDescriptionBirthDateADYearBase] = useState(null);
  const [lifeBaseDescriptionIdElevenTwelveADYearBase, setLifeBaseDescriptionIdElevenTwelveADYearBase] = useState(null);
  const [lifeBaseDescriptionIdTwelveThirteenADYearBase, setLifeBaseDescriptionIdTwelveThirteenADYearBase] = useState(null);
  const [lifeBaseDescriptionIdSumADYearBase, setLifeBaseDescriptionIdSumADYearBase] = useState(null);
  
  const [subconsciousMindDescriptionBirthDateAD, setSubconsciousMindDescriptionBirthDateAD] = useState(null);
  const [subconsciousMindDescriptionIdElevenTwelveAD, setSubconsciousMindDescriptionIdElevenTwelveAD] = useState(null);
  const [subconsciousMindDescriptionIdTwelveThirteenAD, setSubconsciousMindDescriptionIdTwelveThirteenAD] = useState(null);
  const [subconsciousMindDescriptionIdSumAD, setSubconsciousMindDescriptionIdSumAD] = useState(null);

  const [subconsciousMindDescriptionBirthDateADYearBase, setSubconsciousMindDescriptionBirthDateADYearBase] = useState(null);
  const [subconsciousMindDescriptionIdElevenTwelveADYearBase, setSubconsciousMindDescriptionIdElevenTwelveADYearBase] = useState(null);
  const [subconsciousMindDescriptionIdTwelveThirteenADYearBase, setSubconsciousMindDescriptionIdTwelveThirteenADYearBase] = useState(null);
  const [subconsciousMindDescriptionIdSumADYearBase, setSubconsciousMindDescriptionIdSumADYearBase] = useState(null);

  const [hiddenNumberDescriptionAnnoDominiBirthBase, setHiddenNumberDescriptionAnnoDominiBirthBase] = useState(null);
  const [hiddenNumberDescriptionIdElevenTwelveBirthBase, setHiddenNumberDescriptionIdElevenTwelveBirthBase] = useState(null);
  const [hiddenNumberDescriptionIdTwelveThirteenBirthBase, setHiddenNumberDescriptionIdTwelveThirteenBirthBase] = useState(null);
  const [hiddenNumberDescriptionIdSumBirthBase, setHiddenNumberDescriptionIdSumBirthBase] = useState(null);

  const [hiddenNumberDescriptionAnnoDominiYearBase, setHiddenNumberDescriptionAnnoDominiYearBase] = useState(null);
  const [hiddenNumberDescriptionIdElevenTwelveYearBase, setHiddenNumberDescriptionIdElevenTwelveYearBase] = useState(null);
  const [hiddenNumberDescriptionIdTwelveThirteenYearBase, setHiddenNumberDescriptionIdTwelveThirteenYearBase] = useState(null);
  const [hiddenNumberDescriptionIdSumYearBase, setHiddenNumberDescriptionIdSumYearBase] = useState(null);

  const [missingNumberDescriptionsAnnoDominiBirthBase, setMissingNumberDescriptionsAnnoDominiBirthBase] = useState(null);
  const [missingNumberDescriptionsIdElevenTwelveBirthBase, setMissingNumberDescriptionsIdElevenTwelveBirthBase] = useState(null);
  const [missingNumberDescriptionsIdTwelveThirteenBirthBase, setMissingNumberDescriptionsIdTwelveThirteenBirthBase] = useState(null);
  const [missingNumberDescriptionsIdSumBirthBase, setMissingNumberDescriptionsIdSumBirthBase] = useState(null);

  const [missingNumberDescriptionsAnnoDominiYearBase, setMissingNumberDescriptionsAnnoDominiYearBase] = useState(null);
  const [missingNumberDescriptionsIdElevenTwelveYearBase, setMissingNumberDescriptionsIdElevenTwelveYearBase] = useState(null);
  const [missingNumberDescriptionsIdTwelveThirteenYearBase, setMissingNumberDescriptionsIdTwelveThirteenYearBase] = useState(null);
  const [missingNumberDescriptionsIdSumYearBase, setMissingNumberDescriptionsIdSumYearBase] = useState(null);

  const [lifePathAndSubconsciousNumberDescriptionBirthDateAD, setLifePathAndSubconsciousNumberDescriptionBirthDateAD] = useState(null);
  const [lifePathAndSubconsciousNumberDescriptionIdElevenTwelveAD, setLifePathAndSubconsciousNumberDescriptionIdElevenTwelveAD] = useState(null);
  const [lifePathAndSubconsciousNumberDescriptionIdTwelveThirteenAD, setLifePathAndSubconsciousNumberDescriptionTwelveThirteenAD] = useState(null);
  const [lifePathAndSubconsciousNumberDescriptionIdSumAD, setLifePathAndSubconsciousNumberDescriptionIdSumAD] = useState(null);

  const [lifePathAndSubconsciousNumberDescriptionBirthDateADYearBase, setLifePathAndSubconsciousNumberDescriptionBirthDateADYearBase] = useState(null);
  const [lifePathAndSubconsciousNumberDescriptionIdElevenTwelveADYearBase, setLifePathAndSubconsciousNumberDescriptionIdElevenTwelveADYearBase] = useState(null);
  const [lifePathAndSubconsciousNumberDescriptionIdTwelveThirteenADYearBase, setLifePathAndSubconsciousNumberDescriptionTwelveThirteenADYearBase] = useState(null);
  const [lifePathAndSubconsciousNumberDescriptionIdSumADYearBase, setLifePathAndSubconsciousNumberDescriptionIdSumADYearBase] = useState(null);

  const [potentialAndObstaclesFromSevenDescriptionAnnoDominiBirthBase, setPotentialAndObstaclesFromSevenDescriptionAnnoDominiBirthBase] = useState(null);
  const [potentialAndObstaclesFromSevenDescriptionIdElevenTwelveBirthBase, setPotentialAndObstaclesFromSevenDescriptionIdElevenTwelveBirthBase] = useState(null);
  const [potentialAndObstaclesFromSevenDescriptionIdTwelveThirteenBirthBase, setPotentialAndObstaclesFromSevenDescriptionIdTwelveThirteenBirthBase] = useState(null);
  const [potentialAndObstaclesFromSevenDescriptionIdSumBirthBase, setPotentialAndObstaclesFromSevenDescriptionIdSumBirthBase] = useState(null);

  const [potentialAndObstaclesFromSevenDescriptionAnnoDominiYearBase, setPotentialAndObstaclesFromSevenDescriptionAnnoDominiYearBase] = useState(null);
  const [potentialAndObstaclesFromSevenDescriptionIdElevenTwelveYearBase, setPotentialAndObstaclesFromSevenDescriptionIdElevenTwelveYearBase] = useState(null);
  const [potentialAndObstaclesFromSevenDescriptionIdTwelveThirteenYearBase, setPotentialAndObstaclesFromSevenDescriptionIdTwelveThirteenYearBase] = useState(null);
  const [potentialAndObstaclesFromSevenDescriptionIdSumYearBase, setPotentialAndObstaclesFromSevenDescriptionIdSumYearBase] = useState(null);
  
  const [potentialAndObstaclesFromTwoNumberListBirthBase, setPotentialAndObstaclesFromTwoNumberListBirthBase] = useState([]);
  const [potentialAndObstaclesFromTwoNumberListYearBase, setPotentialAndObstaclesFromTwoNumberListYearBase] = useState([]);

  const [goodDirectionBirthDateBirthBase, setGoodDirectionBirthDateBirthBase] = useState(null);
  const [goodDirectionIdElevenTwelveBirthBase, setGoodDirectionIdElevenTwelveBirthBase] = useState(null);
  const [goodDirectionIdTwelveThirteenBirthBase, setGoodDirectionIdTwelveThirteenBirthBase] = useState(null);
  const [goodDirectionIdSumBirthBase, setGoodDirectionIdSumBirthBase] = useState(null);

  const [goodDirectionBirthDateYearBase, setGoodDirectionBirthDateYearBase] = useState(null);
  const [goodDirectionIdElevenTwelveYearBase, setGoodDirectionIdElevenTwelveYearBase] = useState(null);
  const [goodDirectionIdTwelveThirteenYearBase, setGoodDirectionIdTwelveThirteenYearBase] = useState(null);
  const [goodDirectionIdSumYearBase, setGoodDirectionIdSumYearBase] = useState(null);

  const [healthDescription, setHealthDescription] = useState([]);
  const [goodLuckNumberDescription, setGoodLuckNumberDescription] = useState(null);

  const [nameSumDescription, setNameSumDescription] = useState(null);
  const [nameSumAndLifePathNumberDescription, setNameSumAndLifePathNumberDescription] = useState(null);

  const [telephoneSumDescription, setTelephoneSumDescription] = useState(null);

  const [showLifeBaseDescriptionBirthDateAD, setShowLifeBaseDescriptionBirthDateAD] = useState(false);
  const [showSubconsciousDescriptionBirthDateAD, setShowSubconsciousDescriptionBirthDateAD] = useState(false);
  const [showLifePathAndSubconsciousNumberDescriptionBirthDateAD, setShowLifePathAndSubconsciousNumberDescriptionBirthDateAD] = useState(false);
  const [showHiddenAndMissingDescriptionBirthDateAD, setShowHiddenAndMissingDescriptionBirthDateAD] = useState(false);
  
  const [showLifeBaseDescriptionIdElevenTwelveAD, setShowLifeBaseDescriptionIdElevenTwelveAD] = useState(false);
  const [showSubconsciousDescriptionIdElevenTwelveAD, setShowSubconsciousDescriptionIdElevenTwelveAD] = useState(false);
  const [showLifePathAndSubconsciousNumberDescriptionIdElevenTwelveAD, setShowLifePathAndSubconsciousNumberDescriptionIdElevenTwelveAD] = useState(false);
  const [showHiddenAndMissingDescriptionIdElevenTwelveAD, setShowHiddenAndMissingDescriptionIdElevenTwelveAD] = useState(false);
  
  const [showLifeBaseDescriptionIdTwelveThirteenAD, setShowLifeBaseDescriptionIdTwelveThirteenAD] = useState(false);
  const [showSubconsciousDescriptionIdTwelveThirteenAD, setShowSubconsciousDescriptionIdTwelveThirteenAD] = useState(false);
  const [showLifePathAndSubconsciousNumberDescriptionIdTwelveThirteenAD, setShowLifePathAndSubconsciousNumberDescriptionIdTwelveThirteenAD] = useState(false);
  const [showHiddenAndMissingDescriptionIdTwelveThirteenAD, setShowHiddenAndMissingDescriptionIdTwelveThirteenAD] = useState(false);
  
  const [showLifeBaseDescriptionIdSumAD, setShowLifeBaseDescriptionIdSumAD] = useState(false);
  const [showSubconsciousDescriptionIdSumAD, setShowSubconsciousDescriptionIdSumAD] = useState(false);
  const [showLifePathAndSubconsciousNumberDescriptionIdSumAD, setShowLifePathAndSubconsciousNumberDescriptionIdSumAD] = useState(false);
  const [showHiddenAndMissingDescriptionIdSumAD, setShowHiddenAndMissingDescriptionIdSumAD] = useState(false);
  
  const [showPotentialAndObstacleDescriptionFromSevenOne, setShowPotentialAndObstacleDescriptionFromSevenOne] = useState(false);
  const [showPotentialAndObstacleDescriptionFromSevenTwo, setShowPotentialAndObstacleDescriptionFromSevenTwo] = useState(false);
  const [showPotentialAndObstacleDescriptionFromSevenThree, setShowPotentialAndObstacleDescriptionFromSevenThree] = useState(false);
  const [showPotentialAndObstacleDescriptionFromSevenFour, setShowPotentialAndObstacleDescriptionFromSevenFour] = useState(false);

  const [showPotentialAndObstacleDescriptionFromTwoNumberOne, setShowPotentialAndObstacleDescriptionFromTwoNumberOne] = useState(false);
  const [showPotentialAndObstacleDescriptionFromTwoNumberTwo, setShowPotentialAndObstacleDescriptionFromTwoNumberTwo] = useState(false);
  const [showPotentialAndObstacleDescriptionFromTwoNumberThree, setShowPotentialAndObstacleDescriptionFromTwoNumberThree] = useState(false);
  const [showPotentialAndObstacleDescriptionFromTwoNumberFour, setShowPotentialAndObstacleDescriptionFromTwoNumberFour] = useState(false);
  const [showPotentialAndObstacleDescriptionFromTwoNumberFive, setShowPotentialAndObstacleDescriptionFromTwoNumberFive] = useState(false);
  const [showPotentialAndObstacleDescriptionFromTwoNumberSix, setShowPotentialAndObstacleDescriptionFromTwoNumberSix] = useState(false);
  const [showPotentialAndObstacleDescriptionFromTwoNumberSeven, setShowPotentialAndObstacleDescriptionFromTwoNumberSeven] = useState(false);
  const [showPotentialAndObstacleDescriptionFromTwoNumberEight, setShowPotentialAndObstacleDescriptionFromTwoNumberEight] = useState(false);

  const [showPartnerModal, setShowPartnerModal] = useState(false);

  const handleLifeBaseBirthDateADShow = () => setShowLifeBaseDescriptionBirthDateAD(true);
  const handleLifeBaseBirthDateADClose = () => setShowLifeBaseDescriptionBirthDateAD(false);
  const handleLifeBaseIdElevenTwelveADShow = () => setShowLifeBaseDescriptionIdElevenTwelveAD(true);
  const handleLifeBaseIdElevenTwelveADClose = () => setShowLifeBaseDescriptionIdElevenTwelveAD(false);
  const handleLifeBaseIdTwelveThirteenADShow = () => setShowLifeBaseDescriptionIdTwelveThirteenAD(true);
  const handleLifeBaseIdTwelveThirteenADClose = () => setShowLifeBaseDescriptionIdTwelveThirteenAD(false);
  const handleLifeBaseIdSumADShow = () => setShowLifeBaseDescriptionIdSumAD(true);
  const handleLifeBaseIdSumADClose = () => setShowLifeBaseDescriptionIdSumAD(false);

  const handleSubconsciousBirthDateADShow = () => setShowSubconsciousDescriptionBirthDateAD(true);
  const handleSubconsciousBirthDateADClose = () => setShowSubconsciousDescriptionBirthDateAD(false);
  const handleSubconsciousIdElevenTwelveADShow = () => setShowSubconsciousDescriptionIdElevenTwelveAD(true);
  const handleSubconsciousIdElevenTwelveADClose = () => setShowSubconsciousDescriptionIdElevenTwelveAD(false);
  const handleSubconsciousIdTwelveThirteenADShow = () => setShowSubconsciousDescriptionIdTwelveThirteenAD(true);
  const handleSubconsciousIdTwelveThirteenADClose = () => setShowSubconsciousDescriptionIdTwelveThirteenAD(false);
  const handleSubconsciousIdSumADShow = () => setShowSubconsciousDescriptionIdSumAD(true);
  const handleSubconsciousIdSumADClose = () => setShowSubconsciousDescriptionIdSumAD(false);

  const handleHiddenAndMissingBirthDateShow = () => setShowHiddenAndMissingDescriptionBirthDateAD(true);
  const handleHiddenAndMissingBirthDateClose = () => setShowHiddenAndMissingDescriptionBirthDateAD(false);
  const handleHiddenAndMissingIdElevenTwelveShow = () => setShowHiddenAndMissingDescriptionIdElevenTwelveAD(true);
  const handleHiddenAndMissingIdElevenTwelveClose = () => setShowHiddenAndMissingDescriptionIdElevenTwelveAD(false);
  const handleHiddenAndMissingIdTwelveThirteenShow = () => setShowHiddenAndMissingDescriptionIdTwelveThirteenAD(true);
  const handleHiddenAndMissingIdTwelveThirteenClose = () => setShowHiddenAndMissingDescriptionIdTwelveThirteenAD(false);
  const handleHiddenAndMissingIdSumShow = () => setShowHiddenAndMissingDescriptionIdSumAD(true);
  const handleHiddenAndMissingIdSumClose = () => setShowHiddenAndMissingDescriptionIdSumAD(false);

  const handleLifePathAndSubconsciousNumberBirthDateADShow = () => setShowLifePathAndSubconsciousNumberDescriptionBirthDateAD(true);
  const handleLifePathAndSubconsciousNumberBirthDateADClose = () => setShowLifePathAndSubconsciousNumberDescriptionBirthDateAD(false);
  const handleLifePathAndSubconsciousNumberDescriptionIdElevenTwelveADShow = () => setShowLifePathAndSubconsciousNumberDescriptionIdElevenTwelveAD(true);
  const handleLifePathAndSubconsciousNumberDescriptionIdElevenTwelveADClose = () => setShowLifePathAndSubconsciousNumberDescriptionIdElevenTwelveAD(false);
  const handleLifePathAndSubconsciousNumberDescriptionIdTwelveThirteenADShow = () => setShowLifePathAndSubconsciousNumberDescriptionIdTwelveThirteenAD(true);
  const handleLifePathAndSubconsciousNumberDescriptionIdTwelveThirteenADClose = () => setShowLifePathAndSubconsciousNumberDescriptionIdTwelveThirteenAD(false);
  const handleLifePathAndSubconsciousNumberDescriptionIdSumADShow = () => setShowLifePathAndSubconsciousNumberDescriptionIdSumAD(true);
  const handleLifePathAndSubconsciousNumberDescriptionIdSumADClose = () => setShowLifePathAndSubconsciousNumberDescriptionIdSumAD(false);
  
  const handleShowPotentialAndObstacleDescriptionFromSevenOne = () => setShowPotentialAndObstacleDescriptionFromSevenOne(true);
  const handleClosePotentialAndObstacleDescriptionFromSevenOne = () => setShowPotentialAndObstacleDescriptionFromSevenOne(false);
  const handleShowPotentialAndObstacleDescriptionFromSevenTwo = () => setShowPotentialAndObstacleDescriptionFromSevenTwo(true);
  const handleClosePotentialAndObstacleDescriptionFromSevenTwo = () => setShowPotentialAndObstacleDescriptionFromSevenTwo(false);
  const handleShowPotentialAndObstacleDescriptionFromSevenThree = () => setShowPotentialAndObstacleDescriptionFromSevenThree(true);
  const handleClosePotentialAndObstacleDescriptionFromSevenThree = () => setShowPotentialAndObstacleDescriptionFromSevenThree(false);
  const handleShowPotentialAndObstacleDescriptionFromSevenFour = () => setShowPotentialAndObstacleDescriptionFromSevenFour(true);
  const handleClosePotentialAndObstacleDescriptionFromSevenFour = () => setShowPotentialAndObstacleDescriptionFromSevenFour(false);
  
  const handleShowPotentialAndObstacleDescriptionFromTwoNumberOne = () => setShowPotentialAndObstacleDescriptionFromTwoNumberOne(true);
  const handleClosePotentialAndObstacleDescriptionFromTwoNumberOne = () => setShowPotentialAndObstacleDescriptionFromTwoNumberOne(false);
  const handleShowPotentialAndObstacleDescriptionFromTwoNumberTwo = () => setShowPotentialAndObstacleDescriptionFromTwoNumberTwo(true);
  const handleClosePotentialAndObstacleDescriptionFromTwoNumberTwo = () => setShowPotentialAndObstacleDescriptionFromTwoNumberTwo(false);
  const handleShowPotentialAndObstacleDescriptionFromTwoNumberThree = () => setShowPotentialAndObstacleDescriptionFromTwoNumberThree(true);
  const handleClosePotentialAndObstacleDescriptionFromTwoNumberThree = () => setShowPotentialAndObstacleDescriptionFromTwoNumberThree(false);
  const handleShowPotentialAndObstacleDescriptionFromTwoNumberFour = () => setShowPotentialAndObstacleDescriptionFromTwoNumberFour(true);
  const handleClosePotentialAndObstacleDescriptionFromTwoNumberFour = () => setShowPotentialAndObstacleDescriptionFromTwoNumberFour(false);
  
  const handleShowPotentialAndObstacleDescriptionFromTwoNumberFive = () => setShowPotentialAndObstacleDescriptionFromTwoNumberFive(true);
  const handleClosePotentialAndObstacleDescriptionFromTwoNumberFive = () => setShowPotentialAndObstacleDescriptionFromTwoNumberFive(false);
  const handleShowPotentialAndObstacleDescriptionFromTwoNumberSix = () => setShowPotentialAndObstacleDescriptionFromTwoNumberSix(true);
  const handleClosePotentialAndObstacleDescriptionFromTwoNumberSix = () => setShowPotentialAndObstacleDescriptionFromTwoNumberSix(false);
  const handleShowPotentialAndObstacleDescriptionFromTwoNumberSeven = () => setShowPotentialAndObstacleDescriptionFromTwoNumberSeven(true);
  const handleClosePotentialAndObstacleDescriptionFromTwoNumberSeven = () => setShowPotentialAndObstacleDescriptionFromTwoNumberSeven(false);
  const handleShowPotentialAndObstacleDescriptionFromTwoNumberEight = () => setShowPotentialAndObstacleDescriptionFromTwoNumberEight(true);
  const handleClosePotentialAndObstacleDescriptionFromTwoNumberEight = () => setShowPotentialAndObstacleDescriptionFromTwoNumberEight(false);
  
  const handleShowPartnerModal = () => {setShowPartnerModal(true)};
  const handleClosePartnerModal = () => setShowPartnerModal(false);
  
  const lifePathSumData = mainPremiumProfile
  ? [
        { name: '1', sum: baseSelected === 'birthbase' ? mainPremiumProfile.sumLifePathNumberOneBirthBase : mainPremiumProfile.sumLifePathNumberOneYearBase},
        { name: '2', sum: baseSelected === 'birthbase' ? mainPremiumProfile.sumLifePathNumberTwoBirthBase : mainPremiumProfile.sumLifePathNumberTwoYearBase},
        { name: '3', sum: baseSelected === 'birthbase' ? mainPremiumProfile.sumLifePathNumberThreeBirthBase : mainPremiumProfile.sumLifePathNumberThreeYearBase},
        { name: '4', sum: baseSelected === 'birthbase' ? mainPremiumProfile.sumLifePathNumberFourBirthBase : mainPremiumProfile.sumLifePathNumberFourYearBase},
        { name: '5', sum: baseSelected === 'birthbase' ? mainPremiumProfile.sumLifePathNumberFiveBirthBase : mainPremiumProfile.sumLifePathNumberFiveYearBase},
        { name: '6', sum: baseSelected === 'birthbase' ? mainPremiumProfile.sumLifePathNumberSixBirthBase : mainPremiumProfile.sumLifePathNumberSixYearBase},
        { name: '7', sum: baseSelected === 'birthbase' ? mainPremiumProfile.sumLifePathNumberSevenBirthBase : mainPremiumProfile.sumLifePathNumberSevenYearBase},
        { name: '8', sum: baseSelected === 'birthbase' ? mainPremiumProfile.sumLifePathNumberEightBirthBase : mainPremiumProfile.sumLifePathNumberEightYearBase},
        { name: '9', sum: baseSelected === 'birthbase' ? mainPremiumProfile.sumLifePathNumberNineBirthBase : mainPremiumProfile.sumLifePathNumberNineYearBase},
    ].map((item) => ({
        ...item,
        percentage: (item.sum / 4) * 100, // Normalize sum to percentage
    }))
  : [];

  const subsconsciousMindSumNumber = mainPremiumProfile
  ? [
        { name: '1', sum: baseSelected === 'birthbase' ? mainPremiumProfile.sumSubconsciousNumberOneBirthBase : mainPremiumProfile.sumSubconsciousNumberOneYearBase},
        { name: '2', sum: baseSelected === 'birthbase' ? mainPremiumProfile.sumSubconsciousNumberTwoBirthBase : mainPremiumProfile.sumSubconsciousNumberTwoYearBase},
        { name: '3', sum: baseSelected === 'birthbase' ? mainPremiumProfile.sumSubconsciousNumberThreeBirthBase : mainPremiumProfile.sumSubconsciousNumberThreeYearBase},
        { name: '4', sum: baseSelected === 'birthbase' ? mainPremiumProfile.sumSubconsciousNumberFourBirthBase : mainPremiumProfile.sumSubconsciousNumberFourYearBase},
        { name: '5', sum: baseSelected === 'birthbase' ? mainPremiumProfile.sumSubconsciousNumberFiveBirthBase : mainPremiumProfile.sumSubconsciousNumberFiveYearBase},
        { name: '6', sum: baseSelected === 'birthbase' ? mainPremiumProfile.sumSubconsciousNumberSixBirthBase : mainPremiumProfile.sumSubconsciousNumberSixYearBase},
        { name: '7', sum: baseSelected === 'birthbase' ? mainPremiumProfile.sumSubconsciousNumberSevenBirthBase : mainPremiumProfile.sumSubconsciousNumberSevenYearBase},
        { name: '8', sum: baseSelected === 'birthbase' ? mainPremiumProfile.sumSubconsciousNumberEightBirthBase : mainPremiumProfile.sumSubconsciousNumberEightYearBase},
        { name: '9', sum: baseSelected === 'birthbase' ? mainPremiumProfile.sumSubconsciousNumberNineBirthBase : mainPremiumProfile.sumSubconsciousNumberNineYearBase},
    ].map((item) => ({
        ...item,
        percentage: (item.sum / 4) * 100, // Normalize sum to percentage
    }))
  : [];

  const missingNumberSum = mainPremiumProfile
  ? [
        { name: '1', sum: baseSelected === 'birthbase' ? mainPremiumProfile.sumMissingNumberOneBirthBase : mainPremiumProfile.sumMissingNumberOneYearBase},
        { name: '2', sum: baseSelected === 'birthbase' ? mainPremiumProfile.sumMissingNumberTwoBirthBase : mainPremiumProfile.sumMissingNumberTwoYearBase},
        { name: '3', sum: baseSelected === 'birthbase' ? mainPremiumProfile.sumMissingNumberThreeBirthBase : mainPremiumProfile.sumMissingNumberThreeYearBase},
        { name: '4', sum: baseSelected === 'birthbase' ? mainPremiumProfile.sumMissingNumberFourBirthBase : mainPremiumProfile.sumMissingNumberFourYearBase},
        { name: '5', sum: baseSelected === 'birthbase' ? mainPremiumProfile.sumMissingNumberFiveBirthBase : mainPremiumProfile.sumMissingNumberFiveYearBase},
        { name: '6', sum: baseSelected === 'birthbase' ? mainPremiumProfile.sumMissingNumberSixBirthBase : mainPremiumProfile.sumMissingNumberSixYearBase},
        { name: '7', sum: baseSelected === 'birthbase' ? mainPremiumProfile.sumMissingNumberSevenBirthBase :mainPremiumProfile.sumMissingNumberSevenYearBase},
        { name: '8', sum: baseSelected === 'birthbase' ? mainPremiumProfile.sumMissingNumberEightBirthBase :mainPremiumProfile.sumMissingNumberEightYearBase},
        { name: '9', sum: baseSelected === 'birthbase' ? mainPremiumProfile.sumMissingNumberNineBirthBase : mainPremiumProfile.sumMissingNumberNineYearBase},
    ]
  : [];

  const highMissingNumber = missingNumberSum.filter(item => item.sum >= 4);

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, name }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.3;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${name} : ${(percent * 100).toFixed(0)}%`}
        </text>
    );
  };

  useEffect(() => {
      // console.log(user);

      if(!loading && !isAuthenticated){
        navigate('/');
      }

      if(!loading && (!premiumStatus || premiumStatus.status !== "PREMIUM")){
        navigate('/premium/subscribe');
      }
      
      if(!loading && isAuthenticated && !premiumStatus.active){
        navigate('/premium/subscribe');
      }

      if(!loading && isAuthenticated && premiumStatus.active && (mainProfile.identificationNumber === null || mainProfile.identificationNumber.length < 13)){
          navigate('/premium/addidnumber');
      }

      if(!loading && isAuthenticated && mainPremiumProfile && premiumStatus.active && mainProfile.identificationNumber !== null){
        fetchPremiumData(mainPremiumProfile.id);
      }

  }, [loading, isAuthenticated, mainProfile, mainPremiumProfile, navigate]);

  const fetchPremiumData = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL+'/premium/data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(mainPremiumProfile.id), // Send profile ID in the request body
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const data = await response.json();
      
      console.log(data);
      //Birthdate AD
      setLifeBaseDescriptionBirthDateAD(data.lifeBaseDescriptionAnnoDominiBirthBase);
      setLifeBaseDescriptionBirthDateADYearBase(data.lifeBaseDescriptionAnnoDominiYearBase);
      
      setSubconsciousMindDescriptionBirthDateAD(data.subconsciousMindDescriptionAnnoDominiBirthBase);
      setSubconsciousMindDescriptionBirthDateADYearBase(data.subconsciousMindDescriptionAnnoDominiYearBase);
      
      setHiddenNumberDescriptionAnnoDominiBirthBase(data.hiddenNumberDescriptionAnnoDominiBirthBase);
      setHiddenNumberDescriptionAnnoDominiYearBase(data.hiddenNumberDescriptionAnnoDominiYearBase);
      
      setMissingNumberDescriptionsAnnoDominiBirthBase(data.missingNumberDescriptionsAnnoDominiBirthBase);
      setMissingNumberDescriptionsAnnoDominiYearBase(data.missingNumberDescriptionsAnnoDominiYearBase);

      setLifePathAndSubconsciousNumberDescriptionBirthDateAD(data.lifePathAndSubconsciousNumberDescriptionAnnoDominiBirthBase);
      setLifePathAndSubconsciousNumberDescriptionBirthDateADYearBase(data.lifePathAndSubconsciousNumberDescriptionAnnoDominiYearBase);

      //ID 11 - 12
      setLifeBaseDescriptionIdElevenTwelveAD(data.lifeBaseDescriptionIdElevenTwelveBirthBase);
      setLifeBaseDescriptionIdElevenTwelveADYearBase(data.lifeBaseDescriptionIdElevenTwelveYearBase);
      
      setSubconsciousMindDescriptionIdElevenTwelveAD(data.subconsciousMindDescriptionIdElevenTwelveBirthBase);
      setSubconsciousMindDescriptionIdElevenTwelveADYearBase(data.subconsciousMindDescriptionIdElevenTwelveYearBase);
      
      setHiddenNumberDescriptionIdElevenTwelveBirthBase(data.hiddenNumberDescriptionIdElevenTwelveBirthBase);
      setHiddenNumberDescriptionIdElevenTwelveYearBase(data.hiddenNumberDescriptionIdElevenTwelveYearBase);
      
      setMissingNumberDescriptionsIdElevenTwelveBirthBase(data.missingNumberDescriptionsIdElevenTwelveBirthBase);
      setMissingNumberDescriptionsIdElevenTwelveYearBase(data.missingNumberDescriptionsIdElevenTwelveYearBase);

      setLifePathAndSubconsciousNumberDescriptionIdElevenTwelveAD(data.lifePathAndSubconsciousNumberDescriptionIdElevenTwelveBirthBase);
      setLifePathAndSubconsciousNumberDescriptionIdElevenTwelveADYearBase(data.lifePathAndSubconsciousNumberDescriptionIdElevenTwelveYearBase);

      //ID 12 - 13
      setLifeBaseDescriptionIdTwelveThirteenAD(data.lifeBaseDescriptionIdTwelveThirteenBirthBase);
      setLifeBaseDescriptionIdTwelveThirteenADYearBase(data.lifeBaseDescriptionIdTwelveThirteenYearBase);
      
      setSubconsciousMindDescriptionIdTwelveThirteenAD(data.subconsciousMindDescriptionIdTwelveThirteenBirthBase);
      setSubconsciousMindDescriptionIdTwelveThirteenADYearBase(data.subconsciousMindDescriptionIdTwelveThirteenYearBase);
      
      setHiddenNumberDescriptionIdTwelveThirteenBirthBase(data.hiddenNumberDescriptionIdElevenTwelveBirthBase);
      setHiddenNumberDescriptionIdTwelveThirteenYearBase(data.hiddenNumberDescriptionIdElevenTwelveYearBase);
      
      setMissingNumberDescriptionsIdTwelveThirteenBirthBase(data.missingNumberDescriptionsIdTwelveThirteenBirthBase);
      setMissingNumberDescriptionsIdTwelveThirteenYearBase(data.missingNumberDescriptionsIdTwelveThirteenYearBase);

      setLifePathAndSubconsciousNumberDescriptionTwelveThirteenAD(data.lifePathAndSubconsciousNumberDescriptionIdTwelveThirteenBirthBase);
      setLifePathAndSubconsciousNumberDescriptionTwelveThirteenADYearBase(data.lifePathAndSubconsciousNumberDescriptionIdTwelveThirteenYearBase);

      //ID SUM
      setLifeBaseDescriptionIdSumAD(data.lifeBaseDescriptionIdSumBirthBase);
      setLifeBaseDescriptionIdSumADYearBase(data.lifeBaseDescriptionIdSumYearBase);
      
      setSubconsciousMindDescriptionIdSumAD(data.subconsciousMindDescriptionIdSumBirthBase);
      setSubconsciousMindDescriptionIdSumADYearBase(data.subconsciousMindDescriptionIdSumYearBase);
      
      setHiddenNumberDescriptionIdSumBirthBase(data.hiddenNumberDescriptionIdSumBirthBase);
      setHiddenNumberDescriptionIdSumYearBase(data.hiddenNumberDescriptionIdSumYearBase);
      
      setMissingNumberDescriptionsIdSumBirthBase(data.missingNumberDescriptionsIdSumBirthBase);
      setMissingNumberDescriptionsIdSumYearBase(data.missingNumberDescriptionsIdSumYearBase);

      setLifePathAndSubconsciousNumberDescriptionIdSumAD(data.lifePathAndSubconsciousNumberDescriptionIdSumBirthBase);
      setLifePathAndSubconsciousNumberDescriptionIdSumADYearBase(data.lifePathAndSubconsciousNumberDescriptionIdSumYearBase);
      
      //Others

      setHealthDescription(data.healthDescription);

      setPotentialAndObstaclesFromSevenDescriptionAnnoDominiBirthBase(data.potentialAndObstaclesFromSevenDescriptionAnnoDominiBirthBase);
      setPotentialAndObstaclesFromSevenDescriptionAnnoDominiYearBase(data.potentialAndObstaclesFromSevenDescriptionAnnoDominiYearBase);
      
      setPotentialAndObstaclesFromSevenDescriptionIdElevenTwelveBirthBase(data.potentialAndObstaclesFromSevenDescriptionIdElevenTwelveBirthBase);
      setPotentialAndObstaclesFromSevenDescriptionIdElevenTwelveYearBase(data.potentialAndObstaclesFromSevenDescriptionIdElevenTwelveYearBase);
      
      setPotentialAndObstaclesFromSevenDescriptionIdTwelveThirteenBirthBase(data.potentialAndObstaclesFromSevenDescriptionIdTwelveThirteenBirthBase);
      setPotentialAndObstaclesFromSevenDescriptionIdTwelveThirteenYearBase(data.potentialAndObstaclesFromSevenDescriptionIdTwelveThirteenYearBase);
      
      setPotentialAndObstaclesFromSevenDescriptionIdSumBirthBase(data.potentialAndObstaclesFromSevenDescriptionIdSumBirthBase);
      setPotentialAndObstaclesFromSevenDescriptionIdSumYearBase(data.potentialAndObstaclesFromSevenDescriptionIdSumYearBase);

      setPotentialAndObstaclesFromTwoNumberListBirthBase(data.potentialAndObstaclesFromTwoNumberListBirthBase);
      setPotentialAndObstaclesFromTwoNumberListYearBase(data.potentialAndObstaclesFromTwoNumberListYearBase);

      setGoodDirectionBirthDateBirthBase(data.goodDirectionBirthDateBirthBase);
      setGoodDirectionBirthDateYearBase(data.goodDirectionBirthDateYearBase);

      setGoodDirectionIdElevenTwelveBirthBase(data.goodDirectionIdElevenTwelveBirthBase);
      setGoodDirectionIdElevenTwelveYearBase(data.goodDirectionIdElevenTwelveYearBase);
      
      setGoodDirectionIdTwelveThirteenBirthBase(data.goodDirectionIdTwelveThirteenBirthBase);
      setGoodDirectionIdTwelveThirteenYearBase(data.goodDirectionIdTwelveThirteenYearBase);
      
      setGoodDirectionIdSumBirthBase(data.goodDirectionIdSumBirthBase);
      setGoodDirectionIdSumYearBase(data.goodDirectionIdSumYearBase);

      setGoodLuckNumberDescription(data.goodLuckNumberDescription);

      setNameSumDescription(data.nameSumDescription);
      setNameSumAndLifePathNumberDescription(data.nameSumAndLifePathNumberDescription);

      setTelephoneSumDescription(data.telephoneNumberDescription);
      setLoadingPremiumData(false);
      setError('');
    } catch (error) {
      setError(error.message);
      console.error('Error fetching trial version data:', error);
    }
  };

  return (
    <div className="background-element">
      <HeaderLayout onLoginClick={showLoginModal}/>

      <div className="page-content-container">
        <h1 className="page-title">{t('premium_numerology')}</h1>

        <div className="d-flex mb-3">
          <button className={`btn btn-danger ${baseSelected === 'birthbase' ? 'active' : ''}`} style={{marginRight: "10px"}} onClick={() => setBaseSelected('birthbase')}>{t('birthbase')}</button>
          <button className={`btn btn-danger ${baseSelected === 'yearbase' ? 'active' : ''}`} style={{marginLeft: "10px"}} onClick={() => setBaseSelected('yearbase')}>{t('yearbase')}</button>
        </div>

        {error !== '' ? (
          <h3 className="error-message">Error : {error}</h3>
          ):
          <></>
        }

        <h3>{t('information_use')}</h3>
        {!mainProfile ? (
          <p>Loading...</p> 
        ): (
          <>
            <h4>{t('fullname')} : {mainProfile.firstName} {mainProfile.lastName}</h4>
            <h4>{t('birthdate')} : {mainProfile.birthDate} / {mainProfile.birthMonth} / {mainProfile.birthYear}</h4>
            <h4>{t('identification_number')} : {mainProfile.identificationNumber}</h4>
          </>
        )
        }

        <h2 className="lifePathBarChartTitle">{t('your_sum_life_path_number')}</h2>

        {loadingPremiumData || !mainPremiumProfile ? (
            <p>Loading...</p>
        ) : (
          <ResponsiveContainer className="lifePathBarChart">
            <BarChart
              data={lifePathSumData}
              margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis
                domain={[0, 100]}
                tickFormatter={(value) => `${value}%`}
              />
              <Tooltip formatter={(value) => `${value.toFixed(2)}%`} />
              <Legend />
              <Bar dataKey="percentage" fill="#87CEEB" />
            </BarChart>
          </ResponsiveContainer>
        )}

        <h2 className="lifePathBarChartTitle">{t('your_sum_subconscious_number')}</h2>

        {loadingPremiumData || !mainPremiumProfile ? (
            <p>Loading...</p>
        ) : (
          <ResponsiveContainer className="lifePathBarChart">
            <BarChart
                data={subsconsciousMindSumNumber}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis
                    domain={[0, 100]}
                    tickFormatter={(value) => `${value}%`}
                />
                <Tooltip formatter={(value) => `${value.toFixed(2)}%`} />
                <Legend />
                <Bar dataKey="percentage" fill="#90EE90" />
            </BarChart>
          </ResponsiveContainer>
        )}

        {loadingPremiumData || !mainPremiumProfile ? (
            <p>Loading...</p>
        ) : (
          <div className="pieChartSection">
            <div className="pieChartContainer">
              <h4 className="pieChartTitle">{t('life_path_number')}</h4>
              <ResponsiveContainer className="pieChart">
                <PieChart>
                  <Pie
                    data={lifePathSumData.filter(item => item.sum > 0)}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={renderCustomizedLabel} // Optional: Add for labels
                    outerRadius={100}
                    dataKey="sum" // Use the correct key from lifePathSumData
                  >
                    {lifePathSumData
                      .filter(item => item.sum > 0)
                      .map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))
                    }
                  </Pie>
              </PieChart>
              </ResponsiveContainer>
            </div>

            <div className="pieChartContainer">
              <h4 className="pieChartTitle">{t('subconscious_number')}</h4>
              <ResponsiveContainer className="pieChart">
                <PieChart>
                  <Pie
                    data={subsconsciousMindSumNumber.filter(item => item.sum > 0)}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={100}
                    dataKey="sum"
                  >
                    {subsconsciousMindSumNumber
                      .filter(item => item.sum > 0)
                      .map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))
                    }
                  </Pie>
              </PieChart>
              </ResponsiveContainer>
            </div>
          </div>
        )}

        <h2 style={{fontSize: "48px"}}>{t('predict_title')}</h2>
        <div style={{backgroundColor:"grey", width: "100%", maxWidth: "800px", height: "6px", borderRadius: "5px"}}></div>

        {loadingPremiumData || !mainPremiumProfile ? (
          <p>Loading...</p>
        ) : (
          <div className="premiumGroup1 mt-4">
            <p className="premiumGroup1-title">{t('set_number')} 1 : {t('date_month_year_birth')}</p>
            <ul>
              <li>○ {t('life_path_number')} = {baseSelected === 'birthbase' ? mainPremiumProfile.birthDateAnnoDominiBirthBase.numberSeven : mainPremiumProfile.birthDateAnnoDominiYearBase.numberSeven} 
                <p className="premiumGroup1-click" onClick={handleLifeBaseBirthDateADShow}><u>{t('click')}</u></p>
              </li>
              <li>○ {t('subconscious_number')} = {baseSelected === 'birthbase' ? mainPremiumProfile.birthDateAnnoDominiBirthBase.subconsciousNumber: mainPremiumProfile.birthDateAnnoDominiYearBase.subconsciousNumber}
                <p className="premiumGroup1-click" onClick={handleSubconsciousBirthDateADShow}><u>{t('click')}</u></p>
              </li>
            </ul>

            <button className="btn btn-warning mb-4" onClick={handleLifePathAndSubconsciousNumberBirthDateADShow}>ดูความหมาย</button>

            <p className="premiumGroup1-title">{t('set_number')} 2 : {t('identification_number')} 11 - 12</p>
            <ul>
              <li>○ {t('life_path_number')} = {baseSelected === 'birthbase' ? mainPremiumProfile.idElevenTwelveBirthBase.numberSeven : mainPremiumProfile.idElevenTwelveYearBase.numberSeven} 
                <p className="premiumGroup1-click" onClick={handleLifeBaseIdElevenTwelveADShow}><u>{t('click')}</u></p>
              </li>
              <li>○ {t('subconscious_number')} = {baseSelected === 'birthbase' ? mainPremiumProfile.idElevenTwelveBirthBase.subconsciousNumber : mainPremiumProfile.idElevenTwelveYearBase.subconsciousNumber}
                <p className="premiumGroup1-click" onClick={handleSubconsciousIdElevenTwelveADShow}><u>{t('click')}</u></p>
              </li>
            </ul>

            <button className="btn btn-warning mb-4" onClick={handleLifePathAndSubconsciousNumberDescriptionIdElevenTwelveADShow}>ดูความหมาย</button>

            <p className="premiumGroup1-title">{t('set_number')} 3 : {t('identification_number')} 12 - 13</p>
            <ul>
              <li>○ {t('life_path_number')} = {baseSelected === 'birthbase' ? mainPremiumProfile.idTwelveThirteenBirthBase.numberSeven : mainPremiumProfile.idTwelveThirteenYearBase.numberSeven} 
                <p className="premiumGroup1-click" onClick={handleLifeBaseIdTwelveThirteenADShow}><u>{t('click')}</u></p>
              </li>
              <li>○ {t('subconscious_number')} = {baseSelected === 'birthbase' ? mainPremiumProfile.idTwelveThirteenBirthBase.subconsciousNumber : mainPremiumProfile.idTwelveThirteenYearBase.subconsciousNumber}
                <p className="premiumGroup1-click" onClick={handleSubconsciousIdTwelveThirteenADShow}><u>{t('click')}</u></p>
              </li>
            </ul>

            <button className="btn btn-warning mb-4" onClick={handleLifePathAndSubconsciousNumberDescriptionIdTwelveThirteenADShow}>ดูความหมาย</button>

            <p className="premiumGroup1-title">{t('set_number')} 4 : {t('identification_number_sum')}</p>
            <ul>
              <li>○ {t('life_path_number')} = {baseSelected === 'birthbase' ? mainPremiumProfile.idSumBirthBase.numberSeven : mainPremiumProfile.idSumYearBase.numberSeven} 
                <p className="premiumGroup1-click" onClick={handleLifeBaseIdSumADShow}><u>{t('click')}</u></p>
              </li>
              <li>○ {t('subconscious_number')} = {baseSelected === 'birthbase' ? mainPremiumProfile.idSumBirthBase.subconsciousNumber : mainPremiumProfile.idSumYearBase.subconsciousNumber}
                <p className="premiumGroup1-click" onClick={handleSubconsciousIdSumADShow}><u>{t('click')}</u></p>
              </li>
            </ul>

            <button className="btn btn-warning mb-4" onClick={handleLifePathAndSubconsciousNumberDescriptionIdSumADShow}>ดูความหมาย</button>
          </div>

          
        )}

        {highMissingNumber.length > 0 &&
          <h3>{t('high_missing_number')}</h3>
        }

        <ul>
          {highMissingNumber.length > 0 ? (
            highMissingNumber.map((item, index) => {
              // Ensure missingNumberDescriptionsAnnoDominiBirthBase is not null/undefined and is an array
              const description =
                Array.isArray(missingNumberDescriptionsAnnoDominiBirthBase) &&
                missingNumberDescriptionsAnnoDominiBirthBase.find(
                  (desc) => desc.number === Number(item.name) // Convert item.name to a number
                );

              return (
                <li key={index}>
                  ตัวเลข {item.name} : ขาด {item.sum} ชุด : {description ? description.meaning : 'No description available'}
                </li>
              );
            })
          ) : (
            <></>
          )}
        </ul>
        
        {loadingPremiumData || !mainPremiumProfile ? (
          <p>Loading...</p>
        ) : (
          <div className="premiumGroup1">
            <p className="premiumGroup1-title">{t('hidden_missing_number_description')}</p>
            <ul>
              <li>
                <p>○ {t('set_number')} 1 : </p>
                <p className="premiumGroup1-number">เลขที่ซ่อนอยู่ {baseSelected === 'birthbase' ? hiddenNumberDescriptionAnnoDominiBirthBase.number : hiddenNumberDescriptionAnnoDominiYearBase.number}</p>
                <p className="premiumGroup1-click" onClick={handleHiddenAndMissingBirthDateShow}><u>{t('click')}</u></p>
              </li>
              <li>
                <p>○ {t('set_number')} 2 : </p>
                <p className="premiumGroup1-number">เลขที่ซ่อนอยู่ {baseSelected === 'birthbase' ?  hiddenNumberDescriptionIdElevenTwelveBirthBase.number : hiddenNumberDescriptionIdElevenTwelveYearBase.number}</p>
                <p className="premiumGroup1-click" onClick={handleHiddenAndMissingIdElevenTwelveShow}><u>{t('click')}</u></p>
              </li>
              <li>
                <p>○ {t('set_number')} 3 : </p>
                <p className="premiumGroup1-number">เลขที่ซ่อนอยู่ {baseSelected === 'birthbase' ? hiddenNumberDescriptionIdTwelveThirteenBirthBase.number : hiddenNumberDescriptionIdTwelveThirteenYearBase.number}</p>
                <p className="premiumGroup1-click" onClick={handleHiddenAndMissingIdTwelveThirteenShow}><u>{t('click')}</u></p>
              </li>
              <li>
                <p>○ {t('set_number')} 4 : </p>
                <p className="premiumGroup1-number">เลขที่ซ่อนอยู่ {baseSelected === 'birthbase' ? hiddenNumberDescriptionIdSumBirthBase.number : hiddenNumberDescriptionIdSumYearBase.number}</p>
                <p className="premiumGroup1-click" onClick={handleHiddenAndMissingIdSumShow}><u>{t('click')}</u></p>
              </li>
            </ul>
          </div>
        )}

        <div style={{backgroundColor:"grey", width: "100%", maxWidth: "800px", height: "6px", borderRadius: "5px"}}></div>

        
        <h2 className="lifePathBarChartTitle">{t('problem_obstacle_and_hidden_potential')}</h2>
          
        { loadingPremiumData || !mainPremiumProfile ?(
          <p>Loading...</p>
        ) : (
          <div className="premiumGroup1 mt-4">
            <h4>{t('single_number_from_life_path_number')} “O”</h4>
            <ul>
              <li>
                <p>○ {t('set_number')} 1 : {t('date_month_year_birth')} : </p>
                <p className="premiumGroup1-number">{baseSelected === 'birthbase' ? potentialAndObstaclesFromSevenDescriptionAnnoDominiBirthBase.number : potentialAndObstaclesFromSevenDescriptionAnnoDominiYearBase.number}</p>
                <p className="premiumGroup1-click" onClick={handleShowPotentialAndObstacleDescriptionFromSevenOne}><u>{t('click')}</u></p>
              </li>
              <li>
                <p>○ {t('set_number')} 2 : {t('identification_number')} 11 - 12 : </p>
                <p className="premiumGroup1-number">{baseSelected === 'birthbase' ?  potentialAndObstaclesFromSevenDescriptionIdElevenTwelveBirthBase.number : potentialAndObstaclesFromSevenDescriptionIdElevenTwelveYearBase.number}</p>
                <p className="premiumGroup1-click" onClick={handleShowPotentialAndObstacleDescriptionFromSevenTwo}><u>{t('click')}</u></p>
              </li>
              <li>
                <p>○ {t('set_number')} 3 : {t('identification_number')} 12 - 13 : </p>
                <p className="premiumGroup1-number">{baseSelected === 'birthbase' ? potentialAndObstaclesFromSevenDescriptionIdTwelveThirteenBirthBase.number : potentialAndObstaclesFromSevenDescriptionIdTwelveThirteenYearBase.number}</p>
                <p className="premiumGroup1-click" onClick={handleShowPotentialAndObstacleDescriptionFromSevenThree}><u>{t('click')}</u></p>
              </li>
              <li>
                <p>○ {t('set_number')} 4 : {t('identification_number_sum')} : </p>
                <p className="premiumGroup1-number">{baseSelected === 'birthbase' ? potentialAndObstaclesFromSevenDescriptionIdSumBirthBase.number : potentialAndObstaclesFromSevenDescriptionIdSumYearBase.number}</p>
                <p className="premiumGroup1-click" onClick={handleShowPotentialAndObstacleDescriptionFromSevenFour}><u>{t('click')}</u></p>
              </li>
            </ul>
          </div>
        )}

        
        { loadingPremiumData || !mainPremiumProfile ?(
          <p>Loading...</p>
        ) : (
          <div className="premiumGroup1">
            <h4>{t('from_position_ijmn')}</h4>
            <ul>
              <li>
                <p>○ {t('set_number')} 1 : I-J : </p>
                <p className="premiumGroup1-number">{baseSelected === 'birthbase' ? potentialAndObstaclesFromTwoNumberListBirthBase[0].number : potentialAndObstaclesFromTwoNumberListYearBase[0].number}</p>
                <p className="premiumGroup1-click" onClick={handleShowPotentialAndObstacleDescriptionFromTwoNumberOne}><u>{t('click')}</u></p>
              </li>
              <li>
                <p>○ {t('set_number')} 2 : M-N : </p>
                <p className="premiumGroup1-number">{baseSelected === 'birthbase' ? potentialAndObstaclesFromTwoNumberListBirthBase[1].number : potentialAndObstaclesFromTwoNumberListYearBase[1].number}</p>
                <p className="premiumGroup1-click" onClick={handleShowPotentialAndObstacleDescriptionFromTwoNumberTwo}><u>{t('click')}</u></p>
              </li>
              <li>
                <p>○ {t('set_number')} 3 : I-J : </p>
                <p className="premiumGroup1-number">{baseSelected === 'birthbase' ? potentialAndObstaclesFromTwoNumberListBirthBase[2].number : potentialAndObstaclesFromTwoNumberListYearBase[2].number}</p>
                <p className="premiumGroup1-click" onClick={handleShowPotentialAndObstacleDescriptionFromTwoNumberThree}><u>{t('click')}</u></p>
              </li>
              <li>
                <p>○ {t('set_number')} 4 : M-N : </p>
                <p className="premiumGroup1-number">{baseSelected === 'birthbase' ? potentialAndObstaclesFromTwoNumberListBirthBase[3].number : potentialAndObstaclesFromTwoNumberListYearBase[3].number}</p>
                <p className="premiumGroup1-click" onClick={handleShowPotentialAndObstacleDescriptionFromTwoNumberFour}><u>{t('click')}</u></p>
              </li>
              <li>
                <p>○ {t('set_number')} 5 : I-J : </p>
                <p className="premiumGroup1-number">{baseSelected === 'birthbase' ? potentialAndObstaclesFromTwoNumberListBirthBase[4].number : potentialAndObstaclesFromTwoNumberListYearBase[4].number}</p>
                <p className="premiumGroup1-click" onClick={handleShowPotentialAndObstacleDescriptionFromTwoNumberFive}><u>{t('click')}</u></p>
              </li>
              <li>
                <p>○ {t('set_number')} 6 : M-N : </p>
                <p className="premiumGroup1-number">{baseSelected === 'birthbase' ? potentialAndObstaclesFromTwoNumberListBirthBase[5].number : potentialAndObstaclesFromTwoNumberListYearBase[5].number}</p>
                <p className="premiumGroup1-click" onClick={handleShowPotentialAndObstacleDescriptionFromTwoNumberSix}><u>{t('click')}</u></p>
              </li>
              <li>
                <p>○ {t('set_number')} 7 : I-J : </p>
                <p className="premiumGroup1-number">{baseSelected === 'birthbase' ? potentialAndObstaclesFromTwoNumberListBirthBase[6].number : potentialAndObstaclesFromTwoNumberListYearBase[6].number}</p>
                <p className="premiumGroup1-click" onClick={handleShowPotentialAndObstacleDescriptionFromTwoNumberSeven}><u>{t('click')}</u></p>
              </li>
              <li>
                <p>○ {t('set_number')} 8 : M-N : </p>
                <p className="premiumGroup1-number">{baseSelected === 'birthbase' ? potentialAndObstaclesFromTwoNumberListBirthBase[7].number : potentialAndObstaclesFromTwoNumberListYearBase[7].number}</p>
                <p className="premiumGroup1-click" onClick={handleShowPotentialAndObstacleDescriptionFromTwoNumberEight}><u>{t('click')}</u></p>
              </li>
            </ul>
          </div>
        )}

        { loadingPremiumData || !mainPremiumProfile ?(
          <p>Loading...</p>
        ) : (
          <>
            <div className="premiumGroup1">
              <h2>{t('goodluck_direction')}</h2>
              <p>{t('goodluck_direction_description_one')}</p>
              <p>{t('goodluck_direction_description_two')}</p>
              <p>{t('goodluck_direction_description_three')}</p>
              <p>{t('goodluck_direction_description_four')}</p>
              <p>{t('goodluck_direction_description_five')}</p>
            </div>

            <h2>{t('set_number')} 1</h2>
            <DirectionSection directionData={baseSelected === 'birthbase' ? goodDirectionBirthDateBirthBase : goodDirectionBirthDateYearBase}/>
            <h2>{t('set_number')} 2</h2>
            <DirectionSection directionData={baseSelected === 'birthbase' ? goodDirectionIdElevenTwelveBirthBase : goodDirectionIdElevenTwelveYearBase}/>
            <h2>{t('set_number')} 3</h2>
            <DirectionSection directionData={baseSelected === 'birthbase' ? goodDirectionIdTwelveThirteenBirthBase : goodDirectionIdTwelveThirteenYearBase}/>
            <h2>{t('set_number')} 4</h2>
            <DirectionSection directionData={baseSelected === 'birthbase' ? goodDirectionIdSumBirthBase : goodDirectionIdSumYearBase}/>
          </>
        )}

        <h2 style={{color: "#022452", marginTop: "50px"}}>{t('health_title')}</h2>
            
        { loadingPremiumData || !mainPremiumProfile ?(
          <p>Loading...</p>
        ) : (
          <ul className="health-ul">
            {healthDescription.map((healthData, index) => (
              <>
              <li style={{fontSize:"28px"}}><strong>{healthData.number}</strong> - {currentLanguage === 'en' ? healthData.elementEnglish : healthData.elementThai}</li>
              <li>{currentLanguage === 'en' ? healthData.descriptionEnglish : healthData.descriptionThai}</li>
              </>
            ))}
          </ul>
        )}

        
        
        { loadingPremiumData || !mainPremiumProfile ?(
          <p>Loading...</p>
        ) : (
          <div className="premiumGroup1">
            <h3 style={{color: "#022452"}}>{t('goodluck_number_from_life_path_number')} {goodLuckNumberDescription.number} ({currentLanguage === 'en' ? goodLuckNumberDescription.elementEnglish : goodLuckNumberDescription.elementThai})</h3>
            
            <h5>{t('wealth_number')}</h5>
            <h5 className="mb-4" style={goodLuckNumberDescription.wealthNumberControl === true ? { color: "green"} : { color: "red"}}>{t('number')}  : {currentLanguage === 'en' ? goodLuckNumberDescription.wealthDescriptionEnglish : goodLuckNumberDescription.wealthDescriptionThai}</h5>
            
            <h5>{t('success_number')}</h5>
            <h5 className="mb-4" style={goodLuckNumberDescription.supportNumberControl === true ? { color: "green"} : { color: "red"}}>{t('number')}  : {currentLanguage === 'en' ? goodLuckNumberDescription.successDescriptionEnglish : goodLuckNumberDescription.successDescriptionThai}</h5>
            
            <h5>{t('support_number')}</h5>
            <h5 style={goodLuckNumberDescription.successNumberControl === true ? { color: "green"} : { color: "red"}}>{t('number')}  : {currentLanguage === 'en' ? goodLuckNumberDescription.supportDescriptionEnglish : goodLuckNumberDescription.supportDescriptionThai}</h5>
            
            <div style={{backgroundColor:"grey", width: "100%", maxWidth: "800px", height: "6px", borderRadius: "5px", marginTop:"20px"}}></div>
            <h5 style={{color:"green", marginTop:"20px"}}>* สีเขียว คือ ธาตุที่เสริมกัน เราเป็นผู้ควบคุม</h5>
            <h5 style={{color:"red"}}>* สีแดง คือ ธาตุที่เราเป็นฝ่ายถูกควบคุม</h5>
          </div>
        )}

        { loadingPremiumData || !mainPremiumProfile ?(
          <p>Loading...</p>
        ) : (
          <div className="premiumGroup1">
            <h3 className="mt-4" style={{color: "#022452"}}>{t('sum_from_fullname')}</h3>
            <h5>{t('your_number_is')} {nameSumDescription.number}</h5>
            
            <h5 className="mb-4">{currentLanguage === 'en' ? nameSumDescription.meaningEnglish :nameSumDescription.meaningThai}</h5>
            
          </div>
        )}

        { loadingPremiumData || !mainPremiumProfile ?(
          <p>Loading...</p>
        ) : (
          <div className="premiumGroup1">
            <h3 className="mt-4" style={{color: "#022452"}}>{t('sum_from_fullname_and_life_path_number')}</h3>
            <h5>{t('your_number_is')} {nameSumAndLifePathNumberDescription.number}</h5>
            <h5 className="mb-4">{currentLanguage === 'en' ? nameSumAndLifePathNumberDescription.meaningEnglish : nameSumAndLifePathNumberDescription.meaningThai}</h5>
            
          </div>
        )}

        { telephoneSumDescription && 
          <div className="premiumGroup1">
            <h3 className="mt-4" style={{color: "#022452"}}>{t('telephone_description')}</h3>
            <h4><strong>{t('number')} : {telephoneSumDescription.number}</strong></h4>
            <h5><strong>{t('life_base_description')} : </strong> {currentLanguage == 'en' ? telephoneSumDescription.meaningEnglish : telephoneSumDescription.meaning}</h5>
            <h5 className="mb-4"><strong>{t('life_base_job_suggesstion')} : </strong> {currentLanguage == 'en' ? telephoneSumDescription.jobSuggestionEnglish :telephoneSumDescription.jobSuggestion}</h5>
          </div>
        }

        <button className="btn btn-success mt-4" onClick={handleShowPartnerModal}>{t('check_partner')}</button>

        <button className="btn btn-danger mt-4" onClick={() => navigate('/premium/other')}>{t('predict_for_other')}</button>

      </div>

      <LifeBaseDescription 
        visible={showLifeBaseDescriptionBirthDateAD} 
        onClose={handleLifeBaseBirthDateADClose} 
        data={baseSelected === 'birthbase' ? lifeBaseDescriptionBirthDateAD : lifeBaseDescriptionBirthDateADYearBase}
      />

      <LifeBaseDescription 
        visible={showLifeBaseDescriptionIdElevenTwelveAD} 
        onClose={handleLifeBaseIdElevenTwelveADClose} 
        data={baseSelected === 'birthbase' ? lifeBaseDescriptionIdElevenTwelveAD : lifeBaseDescriptionIdElevenTwelveADYearBase}
      />

      <LifeBaseDescription 
        visible={showLifeBaseDescriptionIdTwelveThirteenAD} 
        onClose={handleLifeBaseIdTwelveThirteenADClose} 
        data={baseSelected === 'birthbase' ? lifeBaseDescriptionIdTwelveThirteenAD : lifeBaseDescriptionIdTwelveThirteenADYearBase}
      />

      <LifeBaseDescription 
        visible={showLifeBaseDescriptionIdSumAD}
        onClose={handleLifeBaseIdSumADClose}
        data={baseSelected === 'birthbase' ? lifeBaseDescriptionIdSumAD : lifeBaseDescriptionIdSumADYearBase}
      />

      <SubconsciousDescription 
        visible={showSubconsciousDescriptionBirthDateAD}
        onClose={handleSubconsciousBirthDateADClose}
        data={baseSelected === 'birthbase' ? subconsciousMindDescriptionBirthDateAD : subconsciousMindDescriptionBirthDateADYearBase}
      />

      <SubconsciousDescription 
        visible={showSubconsciousDescriptionIdElevenTwelveAD}
        onClose={handleSubconsciousIdElevenTwelveADClose}
        data={baseSelected === 'birthbase' ? subconsciousMindDescriptionIdElevenTwelveAD : subconsciousMindDescriptionIdElevenTwelveADYearBase}
      />

      <SubconsciousDescription 
        visible={showSubconsciousDescriptionIdTwelveThirteenAD}
        onClose={handleSubconsciousIdTwelveThirteenADClose}
        data={baseSelected === 'birthbase' ? subconsciousMindDescriptionIdTwelveThirteenAD : subconsciousMindDescriptionIdTwelveThirteenADYearBase}
      />

      <SubconsciousDescription 
        visible={showSubconsciousDescriptionIdSumAD}
        onClose={handleSubconsciousIdSumADClose}
        data={baseSelected === 'birthbase' ? subconsciousMindDescriptionIdSumAD : subconsciousMindDescriptionIdSumADYearBase} 
      />

      <HiddenAndMissingDescription 
        visible={showHiddenAndMissingDescriptionBirthDateAD} 
        onClose={handleHiddenAndMissingBirthDateClose} 
        hiddenData={baseSelected === 'birthbase' ? hiddenNumberDescriptionAnnoDominiBirthBase : hiddenNumberDescriptionAnnoDominiYearBase}
        missingData={baseSelected === 'birthbase' ? missingNumberDescriptionsAnnoDominiBirthBase : missingNumberDescriptionsAnnoDominiYearBase}
      />

      <HiddenAndMissingDescription 
        visible={showHiddenAndMissingDescriptionIdElevenTwelveAD} 
        onClose={handleHiddenAndMissingIdElevenTwelveClose} 
        hiddenData={baseSelected === 'birthbase' ? hiddenNumberDescriptionIdElevenTwelveBirthBase : hiddenNumberDescriptionIdElevenTwelveYearBase}
        missingData={baseSelected === 'birthbase' ? missingNumberDescriptionsIdElevenTwelveBirthBase : missingNumberDescriptionsIdElevenTwelveYearBase}
      />

      <HiddenAndMissingDescription 
        visible={showHiddenAndMissingDescriptionIdTwelveThirteenAD} 
        onClose={handleHiddenAndMissingIdTwelveThirteenClose} 
        hiddenData={baseSelected === 'birthbase' ? hiddenNumberDescriptionIdTwelveThirteenBirthBase : hiddenNumberDescriptionIdTwelveThirteenYearBase}
        missingData={baseSelected === 'birthbase' ? missingNumberDescriptionsIdTwelveThirteenBirthBase : missingNumberDescriptionsIdTwelveThirteenYearBase}
      />

      <HiddenAndMissingDescription 
        visible={showHiddenAndMissingDescriptionIdSumAD} 
        onClose={handleHiddenAndMissingIdSumClose} 
        hiddenData={baseSelected === 'birthbase' ? hiddenNumberDescriptionIdSumBirthBase : hiddenNumberDescriptionIdSumYearBase}
        missingData={baseSelected === 'birthbase' ? missingNumberDescriptionsIdSumBirthBase : missingNumberDescriptionsIdSumYearBase}
      />

      <LifePathAndSubconsciousDescription 
        visible={showLifePathAndSubconsciousNumberDescriptionBirthDateAD}
        onClose={handleLifePathAndSubconsciousNumberBirthDateADClose}
        data={baseSelected === 'birthbase' ? lifePathAndSubconsciousNumberDescriptionBirthDateAD : lifePathAndSubconsciousNumberDescriptionBirthDateADYearBase}
      />

      <LifePathAndSubconsciousDescription 
        visible={showLifePathAndSubconsciousNumberDescriptionIdElevenTwelveAD}
        onClose={handleLifePathAndSubconsciousNumberDescriptionIdElevenTwelveADClose}
        data={baseSelected === 'birthbase' ? lifePathAndSubconsciousNumberDescriptionIdElevenTwelveAD : lifePathAndSubconsciousNumberDescriptionIdElevenTwelveADYearBase} 
      />

      <LifePathAndSubconsciousDescription 
        visible={showLifePathAndSubconsciousNumberDescriptionIdTwelveThirteenAD}
        onClose={handleLifePathAndSubconsciousNumberDescriptionIdTwelveThirteenADClose}
        data={baseSelected === 'birthbase' ? lifePathAndSubconsciousNumberDescriptionIdTwelveThirteenAD : lifePathAndSubconsciousNumberDescriptionIdTwelveThirteenADYearBase} 
      />

      <LifePathAndSubconsciousDescription 
        visible={showLifePathAndSubconsciousNumberDescriptionIdSumAD}
        onClose={handleLifePathAndSubconsciousNumberDescriptionIdSumADClose}
        data={baseSelected === 'birthbase' ? lifePathAndSubconsciousNumberDescriptionIdSumAD : lifePathAndSubconsciousNumberDescriptionIdSumADYearBase}
      />

      <PotentialAndObstacleDescriptionFromSeven 
        visible={showPotentialAndObstacleDescriptionFromSevenOne}
        onClose={handleClosePotentialAndObstacleDescriptionFromSevenOne}
        data={baseSelected === 'birthbase' ? potentialAndObstaclesFromSevenDescriptionAnnoDominiBirthBase : potentialAndObstaclesFromSevenDescriptionAnnoDominiYearBase} 
      />

      <PotentialAndObstacleDescriptionFromSeven 
        visible={showPotentialAndObstacleDescriptionFromSevenTwo}
        onClose={handleClosePotentialAndObstacleDescriptionFromSevenTwo}
        data={baseSelected === 'birthbase' ? potentialAndObstaclesFromSevenDescriptionIdElevenTwelveBirthBase : potentialAndObstaclesFromSevenDescriptionIdElevenTwelveYearBase} 
      />

      <PotentialAndObstacleDescriptionFromSeven 
        visible={showPotentialAndObstacleDescriptionFromSevenThree}
        onClose={handleClosePotentialAndObstacleDescriptionFromSevenThree}
        data={baseSelected === 'birthbase' ? potentialAndObstaclesFromSevenDescriptionIdTwelveThirteenBirthBase : potentialAndObstaclesFromSevenDescriptionIdTwelveThirteenYearBase} 
      />

      <PotentialAndObstacleDescriptionFromSeven 
        visible={showPotentialAndObstacleDescriptionFromSevenFour}
        onClose={handleClosePotentialAndObstacleDescriptionFromSevenFour}
        data={baseSelected === 'birthbase' ? potentialAndObstaclesFromSevenDescriptionIdSumBirthBase : potentialAndObstaclesFromSevenDescriptionIdSumYearBase} 
      />

      <PotentialAndObstacleDescriptionFromTwoNumber
        visible={showPotentialAndObstacleDescriptionFromTwoNumberOne}
        onClose={handleClosePotentialAndObstacleDescriptionFromTwoNumberOne}
        data={baseSelected === 'birthbase' ? potentialAndObstaclesFromTwoNumberListBirthBase[0] : potentialAndObstaclesFromTwoNumberListYearBase[0]} 
      />

      <PotentialAndObstacleDescriptionFromTwoNumber
        visible={showPotentialAndObstacleDescriptionFromTwoNumberTwo}
        onClose={handleClosePotentialAndObstacleDescriptionFromTwoNumberTwo}
        data={baseSelected === 'birthbase' ? potentialAndObstaclesFromTwoNumberListBirthBase[1] : potentialAndObstaclesFromTwoNumberListYearBase[1]} 
      />

      <PotentialAndObstacleDescriptionFromTwoNumber
        visible={showPotentialAndObstacleDescriptionFromTwoNumberThree}
        onClose={handleClosePotentialAndObstacleDescriptionFromTwoNumberThree}
        data={baseSelected === 'birthbase' ? potentialAndObstaclesFromTwoNumberListBirthBase[2] : potentialAndObstaclesFromTwoNumberListYearBase[2]} 
      />

      <PotentialAndObstacleDescriptionFromTwoNumber
        visible={showPotentialAndObstacleDescriptionFromTwoNumberFour}
        onClose={handleClosePotentialAndObstacleDescriptionFromTwoNumberFour}
        data={baseSelected === 'birthbase' ? potentialAndObstaclesFromTwoNumberListBirthBase[3] : potentialAndObstaclesFromTwoNumberListYearBase[3]} 
      />

      <PotentialAndObstacleDescriptionFromTwoNumber
        visible={showPotentialAndObstacleDescriptionFromTwoNumberFive}
        onClose={handleClosePotentialAndObstacleDescriptionFromTwoNumberFive}
        data={baseSelected === 'birthbase' ? potentialAndObstaclesFromTwoNumberListBirthBase[4] : potentialAndObstaclesFromTwoNumberListYearBase[4]} 
      />

      <PotentialAndObstacleDescriptionFromTwoNumber
        visible={showPotentialAndObstacleDescriptionFromTwoNumberSix}
        onClose={handleClosePotentialAndObstacleDescriptionFromTwoNumberSix}
        data={baseSelected === 'birthbase' ? potentialAndObstaclesFromTwoNumberListBirthBase[5] : potentialAndObstaclesFromTwoNumberListYearBase[5]} 
      />

      <PotentialAndObstacleDescriptionFromTwoNumber
        visible={showPotentialAndObstacleDescriptionFromTwoNumberSeven}
        onClose={handleClosePotentialAndObstacleDescriptionFromTwoNumberSeven}
        data={baseSelected === 'birthbase' ? potentialAndObstaclesFromTwoNumberListBirthBase[6] : potentialAndObstaclesFromTwoNumberListYearBase[7]} 
      />

      <PotentialAndObstacleDescriptionFromTwoNumber
        visible={showPotentialAndObstacleDescriptionFromTwoNumberEight}
        onClose={handleClosePotentialAndObstacleDescriptionFromTwoNumberEight}
        data={baseSelected === 'birthbase' ? potentialAndObstaclesFromTwoNumberListBirthBase[7] : potentialAndObstaclesFromTwoNumberListYearBase[7]} 
      />

      <PartnerModal
        visible={showPartnerModal}
        onClose={handleClosePartnerModal}
        yourData={!loadingPremiumData ? lifeBaseDescriptionBirthDateAD.number : null}
      />

      <FooterLayout onLoginClick={showLoginModal}/>
      <FooterWithCopyright/>

      <LoginModal visible={isLoginModalVisible} onClose={cancelLoginModal} />
    </div>
  );
};

export default PremiumPage;