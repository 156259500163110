import React, { useState, useContext, useEffect } from 'react';
import HeaderLayout from '../layouts/Header';
import FooterLayout from '../layouts/Footer';
import FooterWithCopyright from '../layouts/FooterWithCopyright';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "./Main.css";
import LoginModal from '../modals/LoginModal';
import { FaCalendarAlt } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import AuthContext from '../AuthContext';
import { t } from 'i18next';

const AddMainIdentificationNumberPage = () => {

    const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
    const showLoginModal = () => setIsLoginModalVisible(true);
    const cancelLoginModal = () => setIsLoginModalVisible(false);

    const [identificationNumber, setIdentificationNumber] = useState('');
    const [telephoneNumber, setTelephoneNumber] = useState('');
    const [error, setError] = useState('');

    const navigate = useNavigate();

    const { token, isAuthenticated, loading, mainProfile, fetchData } = useContext(AuthContext);

    const handleFormSubmit = async (event) => {
        event.preventDefault();
    
        if (identificationNumber.length != 13) {
            
            alert("Please put correct format of ID.");
            return;
        }

        const data = {
            identificationNumber: identificationNumber,
            telephoneNumber: telephoneNumber
        };
    
        try {
            const response = await fetch(process.env.REACT_APP_API_URL+'/user/setmainid', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(data),
            });
    
            if (response.ok) {
                await fetchData();
                navigate(`/premium`);
            } else {
                console.error('Failed to add ID:', response.statusText);
                setError(response.statusText);
            }
        } catch (error) {
            console.error('Error add ID:', error);
            setError(error.message);
        }
    };

    useEffect(() => {
        if(!isAuthenticated){
            navigate('/');
        }

        if(!loading && mainProfile.identificationNumber){
            if(mainProfile.identificationNumber.length == 13){
                navigate('/premium');
            }
        }

        if(!loading && mainProfile && mainProfile.telephoneNumber){
            setTelephoneNumber(mainProfile.telephoneNumber);
        }

    }, [loading, mainProfile]);

    return (
        <div className="background-element">
            <HeaderLayout onLoginClick={showLoginModal}/>

            <div className="page-content-container">

                <h1 className="page-text">โปรดเพิ่มข้อมูลรหัสบัตรประชาชน</h1>

                <div className="form-container mt-3">
                    <form onSubmit={handleFormSubmit} className="border p-4 rounded shadow-sm bg-light">
                        {error !== '' ? (
                            <h3 className="error-message">Error : {error}</h3>
                            ):
                            <></>
                        }
                        <div className="mb-3">
                            <label className="form-label">{t('identification_number')}</label>
                            <input
                                type="text"
                                className="form-control"
                                value={identificationNumber}
                                onChange={(e) => setIdentificationNumber(e.target.value)}
                                placeholder="Enter your identification number"
                                required
                            />

                            <label className="form-label mt-4">{t('telephone_number')}</label>
                            <input
                                type="text"
                                className="form-control"
                                value={telephoneNumber}
                                onChange={(e) => setTelephoneNumber(e.target.value)}
                                placeholder="Enter your telephoneNumber"
                                required
                            />
                        </div>

                        <button type="submit" className="btn btn-primary w-100">
                        Add ID Number
                        </button>
                    </form>
                </div>
            </div>

            <FooterLayout onLoginClick={showLoginModal}/>
            <FooterWithCopyright/>

            <LoginModal visible={isLoginModalVisible} onClose={cancelLoginModal} />
        </div>
    );
};

export default AddMainIdentificationNumberPage;
