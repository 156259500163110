import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import translationEN from './locales/en.json';
import translationTH from './locales/th.json';


const resources = {
    en: {
      translation: translationEN
    },
    th: {
      translation: translationTH
    }
};
  
i18n
  .use(HttpApi) // Load translations via HTTP
  .use(LanguageDetector) // Detect language automatically
  .use(initReactI18next) // Passes i18n instance to react-i18next
  .init({
    resources,
    fallbackLng: 'th', // Default language is Thai
    lng: 'th',
    debug: false,
    interpolation: {
      escapeValue: false
    }
});

export default i18n;