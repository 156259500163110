import React, { useState, useContext, useEffect } from 'react';
import HeaderLayout from '../layouts/Header';
import FooterLayout from '../layouts/Footer';
import FooterWithCopyright from '../layouts/FooterWithCopyright';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "./Main.css";
import Triangle from '../components/Triangle';
import LoginModal from '../modals/LoginModal';
import AuthContext from '../AuthContext';
import LifeBaseDescription from '../components/LifeBaseDescription';
import SubconsciousDescription from '../components/SubconsciousDescription';
import InternalExternalDescription from '../components/InternalExternalDescription';
import HiddenAndMissingDescription from '../components/HiddenAndMissingDescription';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const OtherPredictPage = () => {
  const { id } = useParams();

  const { isAuthenticated, token, loading } = useContext(AuthContext);
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const showLoginModal = () => setIsLoginModalVisible(true);
  const cancelLoginModal = () => setIsLoginModalVisible(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [error, setError] = useState('');

  const [profile, setProfile] = useState(null);
  const [triangleBirthDateBirthBaseAD, setTriangleBirthDateBirthBaseAD] = useState(null);
  const [lifeBaseDescriptionBirthBaseAD, setLifeBaseDescriptionBirthBaseAD] = useState(null);
  const [subconsciousMindDescriptionBirthBaseAD, setSubconsciousMindDescriptionBirthBaseAD] = useState(null);
  const [internalSelfDescriptionBirthBaseAD, setInternalSelfDescriptionBirthBaseAD] = useState(null);
  const [externalSelfDescriptionBirthBaseAD, setExternalSelfDescriptionBirthBaseAD] = useState(null);
  const [hiddenNumberDescriptionBirthBaseAD, setHiddenNumberDescriptionBirthBaseAD] = useState(null);
  const [missingNumberDescriptionsBirthBaseAD, setMissingNumberDescriptionsBirthBaseAD] = useState(null);

  const [triangleBirthDateYearBaseAD, setTriangleBirthDateYearBaseAD] = useState(null);
  const [lifeBaseDescriptionYearBaseAD, setLifeBaseDescriptionYearBaseAD] = useState(null);
  const [subconsciousMindDescriptionYearBaseAD, setSubconsciousMindDescriptionYearBaseAD] = useState(null);
  const [internalSelfDescriptionYearBaseAD, setInternalSelfDescriptionYearBaseAD] = useState(null);
  const [externalSelfDescriptionYearBaseAD, setExternalSelfDescriptionYearBaseAD] = useState(null);
  const [hiddenNumberDescriptionYearBaseAD, setHiddenNumberDescriptionYearBaseAD] = useState(null);
  const [missingNumberDescriptionsYearBaseAD, setMissingNumberDescriptionsYearBaseAD] = useState(null);

  const [baseSelected, setBaseSelected] = useState('birthbase');

  const [showLifeBaseDescription, setShowLifeBaseDescription] = useState(false);
  const [showSubconsciousDescription, setShowSubconsciousDescription] = useState(false);
  const [showInternalExternalDescription, setShowInternalExternalDescription] = useState(false);
  const [showHiddenAndMissingDescription, setShowHiddenAndMissingDescription] = useState(false);

  const handleLifeBaseShow = () => setShowLifeBaseDescription(true);
  const handleLifeBaseClose = () => setShowLifeBaseDescription(false);

  const handleSubconsciousShow = () => setShowSubconsciousDescription(true);
  const handleSubconsciousClose = () => setShowSubconsciousDescription(false);

  const handleInternalExternalShow = () => setShowInternalExternalDescription(true);
  const handleInternalExternalClose = () => setShowInternalExternalDescription(false);

  const handleHiddenAndMissingShow = () => setShowHiddenAndMissingDescription(true);
  const handleHiddenAndMissingClose = () => setShowHiddenAndMissingDescription(false);

  const fetchTrialData = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL+'/trial/data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(id),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const data = await response.json();

      setError('');
      setProfile(data.profile);
      setTriangleBirthDateBirthBaseAD(data.profile.birthDateAnnoDominiBirthBase);
      setLifeBaseDescriptionBirthBaseAD(data.lifeBaseDescriptionAnnoDominiBirthBase);
      setSubconsciousMindDescriptionBirthBaseAD(data.subconsciousMindDescriptionAnnoDominiBirthBase);
      setInternalSelfDescriptionBirthBaseAD(data.internalSelfDescriptionAnnoDominiBirthBase);
      setExternalSelfDescriptionBirthBaseAD(data.externalSelfDescriptionAnnoDominiBirthBase);
      setHiddenNumberDescriptionBirthBaseAD(data.hiddenNumberDescriptionAnnoDominiBirthBase);
      setMissingNumberDescriptionsBirthBaseAD(data.missingNumberDescriptionsAnnoDominiBirthBase);
      
      setTriangleBirthDateYearBaseAD(data.profile.birthDateAnnoDominiYearBase);
      setLifeBaseDescriptionYearBaseAD(data.lifeBaseDescriptionAnnoDominiYearBase);
      setSubconsciousMindDescriptionYearBaseAD(data.subconsciousMindDescriptionAnnoDominiYearBase);
      setInternalSelfDescriptionYearBaseAD(data.internalSelfDescriptionAnnoDominiYearBase);
      setExternalSelfDescriptionYearBaseAD(data.externalSelfDescriptionAnnoDominiYearBase);
      setHiddenNumberDescriptionYearBaseAD(data.hiddenNumberDescriptionAnnoDominiYearBase);
      setMissingNumberDescriptionsYearBaseAD(data.missingNumberDescriptionsAnnoDominiYearBase);
    } catch (error) {
      setError(error.message);
      console.error('Error fetching trial version data:', error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchTrialData();
    }
  }, [id]);

  if (error) return <div>Error: {error}</div>;
  if (!profile) return <div>Loading...</div>;

    return (
        <div className="background-element">
            <HeaderLayout onLoginClick={showLoginModal}/>

            <div className="page-content-container">
                {!loading &&
                <div className="main-layout">
                    <div className="d-flex mb-3">
                      <button className={`btn btn-danger ${baseSelected === 'birthbase' ? 'active' : ''}`} style={{marginRight: "10px"}} onClick={() => setBaseSelected('birthbase')}>{t('birthbase')}</button>
                      <button className={`btn btn-danger ${baseSelected === 'yearbase' ? 'active' : ''}`} style={{marginLeft: "10px"}} onClick={() => setBaseSelected('yearbase')}>{t('yearbase')}</button>
                    </div>

                    {error !== '' ? (
                      <h3 className="error-message">Error : {error}</h3>
                      ):
                      <></>
                    }

                    <h1>{profile.firstName} {profile.lastName}</h1>
                    <h1>เกิด {profile.birthDate}/{profile.birthMonth}/{ baseSelected === 'birthbase' ? profile.birthYear : new Date().getFullYear()}</h1>

                    <br/>

                    <Triangle data={baseSelected === 'birthbase' ? triangleBirthDateBirthBaseAD : triangleBirthDateYearBaseAD}/>

                    <br/>

                    <button className="btn btn-primary mb-4" onClick={handleLifeBaseShow}>{t('life_base_description')}</button>
                    <button className="btn btn-secondary mb-4" onClick={handleSubconsciousShow}>{t('subsconscious_mind_description')}</button>
                    <button className="btn btn-success mb-4" onClick={handleInternalExternalShow}>{t('internal_external_self_description')}</button>
                    <button className="btn btn-warning mb-4" onClick={handleHiddenAndMissingShow}>{t('hidden_missing_number_description')}</button>
                    <button className="btn btn-danger mb-4" onClick={() => navigate('/other')}>{t('back')}</button>

                    <LifeBaseDescription 
                        visible={showLifeBaseDescription} 
                        onClose={handleLifeBaseClose} 
                        data={baseSelected === 'birthbase' ? lifeBaseDescriptionBirthBaseAD : lifeBaseDescriptionYearBaseAD} 
                    />

                    <SubconsciousDescription 
                      visible={showSubconsciousDescription} 
                      onClose={handleSubconsciousClose} 
                      data={baseSelected === 'birthbase' ? subconsciousMindDescriptionBirthBaseAD : subconsciousMindDescriptionYearBaseAD} 
                    />

                    <InternalExternalDescription
                      visible={showInternalExternalDescription} 
                      onClose={handleInternalExternalClose} 
                      internalData={baseSelected === 'birthbase' ? internalSelfDescriptionBirthBaseAD : internalSelfDescriptionYearBaseAD}
                      externalData={baseSelected === 'birthbase' ? externalSelfDescriptionBirthBaseAD : externalSelfDescriptionYearBaseAD}
                    />

                    <HiddenAndMissingDescription 
                      visible={showHiddenAndMissingDescription} 
                      onClose={handleHiddenAndMissingClose} 
                      hiddenData={baseSelected === 'birthbase' ? hiddenNumberDescriptionBirthBaseAD : hiddenNumberDescriptionYearBaseAD}
                      missingData={baseSelected === 'birthbase' ? missingNumberDescriptionsBirthBaseAD : missingNumberDescriptionsYearBaseAD}
                    />
                </div>
                }
            </div>

            <FooterLayout onLoginClick={showLoginModal}/>
            <FooterWithCopyright/>

            <LoginModal visible={isLoginModalVisible} onClose={cancelLoginModal} />
        </div>

    );

};

export default OtherPredictPage;
