import React, { useEffect, useState, useContext } from 'react';
import HeaderLayout from '../layouts/Header';
import FooterLayout from '../layouts/Footer';
import FooterWithCopyright from '../layouts/FooterWithCopyright';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "./Main.css";
import AuthContext from '../AuthContext';
import LoginModal from '../modals/LoginModal';
import { useNavigate } from 'react-router-dom';

const PremiumSubscribePage = () => {

    const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
    const showLoginModal = () => setIsLoginModalVisible(true);
    const navigate = useNavigate();
    const cancelLoginModal = () => setIsLoginModalVisible(false);
    const { isAuthenticated, user, mainProfile, premiumStatus, loading } = useContext(AuthContext);

    const handleActivateCodeClick = () => {
        if(!loading && isAuthenticated){
            navigate('/activate/code');
        }else{
            showLoginModal();
        }
    }

    useEffect(() => {
        if(!loading && isAuthenticated && !premiumStatus.active){
            navigate('/premium/subscribe');
        }
    }, [loading, mainProfile, navigate]);

    return (
        <div className="background-element">
            <HeaderLayout onLoginClick={showLoginModal}/>

            <div className="page-content-container">
                <h1 className="page-title">PREMIUM SUBSCRIBE</h1>

                <h1 className="page-title">Activate Now</h1>
                <button className="btn btn-success mt-4" onClick={handleActivateCodeClick}>Activate Code</button>

                <h1 className="page-title">หรือติดตามข่าวสาร</h1>
                <a href="https://www.facebook.com/LightofSoul567" style={{display:"flex", justifyContent: "center", alignItems:"center"}}>
                    <img src="/facebook.png" className="facebook-image"/>
                </a>

                <a href="https://www.youtube.com/@lightofsoulbynut" style={{display:"flex", justifyContent: "center", alignItems:"center"}}>
                    <img src="/youtube.png"style={{width: "60%", borderRadius: "10px", marginTop: "20px"}}/>
                </a>

                <img src="/line-official.png"style={{width: "60%", maxWidth:"200px", borderRadius: "10px", marginTop: "20px"}}/>

            </div>

            <FooterLayout onLoginClick={showLoginModal}/>
            <FooterWithCopyright/>

            <LoginModal visible={isLoginModalVisible} onClose={cancelLoginModal} />
        </div>
    
    );
};

export default PremiumSubscribePage;