import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Triangle from '../components/Triangle';
import LifeBaseDescription from '../components/LifeBaseDescription';
import InternalExternalDescription from '../components/InternalExternalDescription';
import HiddenAndMissingDescription from '../components/HiddenAndMissingDescription';
import SubconsciousDescription from '../components/SubconsciousDescription';

const TrialVersionMainPage = () => {
  const { id } = useParams();
  const [profile, setProfile] = useState(null);
  const [triangleBirthBaseAD, setTriangleBirthDateBirthBaseAD] = useState(null);
  const [lifeBaseDescription, setLifeBaseDescription] = useState(null);
  const [subconsciousMindDescription, setSubconsciousMindDescription] = useState(null);
  const [internalSelfDescription, setInternalSelfDescription] = useState(null);
  const [externalSelfDescription, setExternalSelfDescription] = useState(null);
  const [hiddenNumberDescription, setHiddenNumberDescription] = useState(null);
  const [missingNumberDescriptions, setMissingNumberDescriptions] = useState(null);
  const [error, setError] = useState('');
  const [showLifeBaseDescription, setShowLifeBaseDescription] = useState(false);
  const [showSubconsciousDescription, setShowSubconsciousDescription] = useState(false);
  const [showInternalExternalDescription, setShowInternalExternalDescription] = useState(false);
  const [showHiddenAndMissingDescription, setShowHiddenAndMissingDescription] = useState(false);

  const handleLifeBaseShow = () => setShowLifeBaseDescription(true);
  const handleLifeBaseClose = () => setShowLifeBaseDescription(false);

  const handleSubconsciousShow = () => setShowSubconsciousDescription(true);
  const handleSubconsciousClose = () => setShowSubconsciousDescription(false);

  const handleInternalExternalShow = () => setShowInternalExternalDescription(true);
  const handleInternalExternalClose = () => setShowInternalExternalDescription(false);

  const handleHiddenAndMissingShow = () => setShowHiddenAndMissingDescription(true);
  const handleHiddenAndMissingClose = () => setShowHiddenAndMissingDescription(false);

  const fetchTrialData = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL+'/trial/data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(id), // Send profile ID in the request body
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const data = await response.json();
      setProfile(data.profile);
      setTriangleBirthDateBirthBaseAD(data.profile.birthDateAnnoDominiBirthBase);
      setLifeBaseDescription(data.lifeBaseDescription);
      setSubconsciousMindDescription(data.subconsciousMindDescription);
      setInternalSelfDescription(data.internalSelfDescription);
      setExternalSelfDescription(data.externalSelfDescription);
      setHiddenNumberDescription(data.hiddenNumberDescription);
      setMissingNumberDescriptions(data.missingNumberDescriptions);
      // console.log(data.profile.birthDateAnnoDominiBirthBase);
      setError('');
    } catch (error) {
      setError(error.message);
      console.error('Error fetching trial version data:', error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchTrialData();
    }
  }, [id]);

  if (error) return <div>Error: {error}</div>;
  if (!profile) return <div>Loading...</div>;

  return (
    <div className="main-layout">
      {error !== '' ? (
          <h3 className="error-message">Error : {error}</h3>
          ):
          <></>
      }

      <h2>Trial Version</h2>
      <h1>{profile.firstName} {profile.lastName}</h1>
      <h1>เกิด {profile.birthDate}/{profile.birthMonth}/{profile.birthYear}</h1>

      <br/>

      <Triangle data={triangleBirthBaseAD}/>

      <br/>

      <button className="btn btn-primary mb-4" onClick={handleLifeBaseShow}>Show Life Base Description</button>
      <button className="btn btn-secondary mb-4" onClick={handleSubconsciousShow}>Subconscious Mind Description</button>
      <button className="btn btn-success mb-4" onClick={handleInternalExternalShow}>Internal/External Self Description</button>
      <button className="btn btn-warning" onClick={handleHiddenAndMissingShow}>Hidden/Missing Number Description</button>

      
      <div>

        <LifeBaseDescription 
          visible={showLifeBaseDescription} 
          onClose={handleLifeBaseClose} 
          data={lifeBaseDescription} 
        />

        <SubconsciousDescription 
          visible={showSubconsciousDescription} 
          onClose={handleSubconsciousClose} 
          data={subconsciousMindDescription} 
        />

        <InternalExternalDescription
          visible={showInternalExternalDescription} 
          onClose={handleInternalExternalClose} 
          internalData={internalSelfDescription}
          externalData={externalSelfDescription}
        />

        <HiddenAndMissingDescription 
          visible={showHiddenAndMissingDescription} 
          onClose={handleHiddenAndMissingClose} 
          hiddenData={hiddenNumberDescription}
          missingData={missingNumberDescriptions} 
        />

      </div>
    </div>
  );

};

export default TrialVersionMainPage;
