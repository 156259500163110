import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const LifeBaseDescription = ({ visible, onClose, data }) => {
  const { i18n, t } = useTranslation();
  const currentLanguage = i18n.language;

  if (!data) return null; // Check if data is available

  return (
    <Modal show={visible} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t('life_base_description')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h1>{t('number')} : {data.number}</h1>
        <p><strong>{t('life_base_description')} : </strong> {currentLanguage == 'en' ? data.meaningEnglish : data.meaning}</p>
        <p><strong>{t('life_base_job_suggesstion')} : </strong> {currentLanguage == 'en' ? data.jobSuggestionEnglish :data.jobSuggestion}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LifeBaseDescription;